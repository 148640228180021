import { createAction, createReducer } from '@reduxjs/toolkit'

// ACTIONS
const stepEnableFocus = createAction('step/enablefocus')
const stepDisableFocus = createAction('step/disablefocus')

const enablePrimaryButton = createAction('step/enablePrimaryButton')
const togglePrimaryButton = createAction('step/togglePrimaryButton')

const stepEnableFacilitatorGraphics = createAction('step/enableFacilitatorGraphics')
const stepDisableFacilitatorGraphics = createAction('step/disableFacilitatorGraphics')

const showBottomBar = createAction('step/showBottomBar')

// REDUCER
export const initialState = {
  focus: false,
  enabledPrimaryButton: true,
  enableFacilitatorGraphics: false,
  showBottomBar: true
}

const stepReducer = createReducer(initialState, {
  [stepEnableFocus.type]: (state) => {
    state.focus = true
  },
  [stepDisableFocus.type]: (state) => {
    state.focus = false
  },
  [enablePrimaryButton.type]: (state, action) => {
    state.enabledPrimaryButton = action.payload
  },
  [togglePrimaryButton.type]: (state) => {
    state.enabledPrimaryButton = !state.enabledPrimaryButton
  },
  [stepEnableFacilitatorGraphics.type]: (state) => {
    state.enableFacilitatorGraphics = true
  },
  [stepDisableFacilitatorGraphics.type]: (state) => {
    state.enableFacilitatorGraphics = false
  },
  [showBottomBar.type]: (state, action) => {
    state.showBottomBar = action.payload
  }
})

export default stepReducer

// PUBLIC ACTIONS
export const enableFocus = () => (dispatch) => {
  dispatch(stepEnableFocus())
}

export const disableFocus = () => (dispatch) => {
  dispatch(stepDisableFocus())
}

export const setEnablePrimaryButton = (value) => (dispatch) => {
  dispatch(enablePrimaryButton(value))
}

export const toggleEnablePrimaryButton = () => (dispatch) => {
  dispatch(togglePrimaryButton())
}

export const enableFacilitatorGraphics = () => (dispatch) => {
  dispatch(stepEnableFacilitatorGraphics())
}

export const disableFacilitatorGraphics = () => (dispatch) => {
  dispatch(stepDisableFacilitatorGraphics())
}

export const setShowBottomBar = (value) => (dispatch) => {
  dispatch(showBottomBar(value))
}
