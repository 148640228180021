import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// ACTIONS
const productDevelopmentRequested = createAction('productDevelopment/requested')
const productDevelopmentRequestFailed = createAction('productDevelopment/requestFailed')
const productDevelopmentReceived = createAction('productDevelopment/received')

const fireRawMaterialEmployeeRequested = createAction('fireRawMaterialEmployee/requested')
const fireRawMaterialEmployeeRequestFailed = createAction('fireRawMaterialEmployee/requestFailed')
const fireRawMaterialEmployeeReceived = createAction('fireRawMaterialEmployee/received')

const fireProductEmployeeRequested = createAction('fireProductEmployee/requested')
const fireProductEmployeeReceived = createAction('fireProductEmployee/received')
const fireProductEmployeeRequestFailed = createAction('fireProductEmployee/requestFailed')

const fireProcurementEmployeeRequested = createAction('fireProcurementEmployee/requested')
const fireProcurementEmployeeReceived = createAction('fireProcurementEmployee/received')
const fireProcurementEmployeeRequestFailed = createAction('fireProcurementEmployee/requestFailed')

const hireProcurementEmployeeRequested = createAction('hireProcurementEmployee/requested')
const hireProcurementEmployeeReceived = createAction('hireProcurementEmployee/received')
const hireProcurementEmployeeRequestFailed = createAction('hireProcurementEmployee/requestFailed')

const productDevelopmentAdded = createAction('productDevelopment/added')

const productDevelopmentRemoved = createAction('productDevelopment/removed')

const productDevelopmentSendRequested = createAction('productDevelopment/sendRequested')
const productDevelopmentSendReceived = createAction('productDevelopment/sendReceived')
const productDevelopmentSendRequestFailed = createAction('productDevelopment/sendRequestFailed')

// REDUCER
export const initialState = {
  data: [],
  toDevelop: [],
  loading: false,
  sendProductDevelopmentLoading: false,
  loadSuccess: false,
  success: false,
  fireRawMaterialEmployeeSuccess: false,
  fireProductEmployeeSuccess: false,
  fireProcurementEmployeeSuccess: false,
  hireProcurementEmployeeSuccess: false,
  fireRawMaterialEmployeeLoading: false,
  fireProductEmployeeLoading: false,
  fireProcurementEmployeeLoading: false,
  hireProcurementEmployeeLoading: false,
  sendProductDevelopmentSuccess: false
}

const productDevelopmentReducer = createReducer(initialState, {
  [productDevelopmentRequested.type]: (state) => {
    state.loading = true
    state.success = false
    state.loadSuccess = false
  },
  [productDevelopmentReceived.type]: (state, action) => {
    state.loading = false
    state.loadSuccess = true
    state.data = action.payload.data
  },
  [productDevelopmentRequestFailed.type]: (state, action) => {
    state.loading = false
    state.success = false
    state.loadSuccess = false
    state.toDevelop = []
    toast.error(action.payload.message)
  },
  // Fire
  [fireRawMaterialEmployeeRequested.type]: (state) => {
    state.fireRawMaterialEmployeeLoading = true
    state.fireRawMaterialEmployeeSuccess = false
  },
  [fireRawMaterialEmployeeReceived.type]: (state, action) => {
    state.fireRawMaterialEmployeeLoading = false
    state.fireRawMaterialEmployeeSuccess = true
    state.data = action.payload.data
  },
  [fireRawMaterialEmployeeRequestFailed.type]: (state, action) => {
    state.fireRawMaterialEmployeeLoading = false
    state.fireRawMaterialEmployeeSuccess = false
    toast.error(action.payload.message)
  },
  // Fire
  [fireProductEmployeeRequested.type]: (state) => {
    state.fireProductEmployeeLoading = true
    state.fireProductEmployeeSuccess = false
  },
  [fireProductEmployeeReceived.type]: (state, action) => {
    state.fireProductEmployeeLoading = false
    state.fireProductEmployeeSuccess = true
    state.data = action.payload.data
  },
  [fireProductEmployeeRequestFailed.type]: (state, action) => {
    state.fireProductEmployeeLoading = false
    state.fireProductEmployeeSuccess = false
    toast.error(action.payload.message)
  },
  // Fire procurement employee
  [fireProcurementEmployeeRequested.type]: (state) => {
    state.fireProcurementEmployeeLoading = true
    state.fireProcurementEmployeeSuccess = false
  },
  [fireProcurementEmployeeReceived.type]: (state, action) => {
    state.fireProcurementEmployeeLoading = false
    state.fireProcurementEmployeeSuccess = true
    state.data = action.payload.data
  },
  [fireProcurementEmployeeRequestFailed.type]: (state, action) => {
    state.fireProcurementEmployeeLoading = false
    state.fireProcurementEmployeeSuccess = false
    toast.error(action.payload.message)
  },
  // hire procurement employee
  [hireProcurementEmployeeRequested.type]: (state) => {
    state.hireProcurementEmployeeLoading = true
    state.hireProcurementEmployeeSuccess = false
  },
  [hireProcurementEmployeeReceived.type]: (state, action) => {
    state.hireProcurementEmployeeLoading = false
    state.hireProcurementEmployeeSuccess = true
    state.data = action.payload.data
  },
  [hireProcurementEmployeeRequestFailed.type]: (state, action) => {
    state.hireProcurementEmployeeLoading = false
    state.hireProcurementEmployeeSuccess = false
    toast.error(action.payload.message)
  },
  [productDevelopmentAdded.type]: (state, action) => {
    state.toDevelop = [...state.toDevelop, action.payload]
  },
  [productDevelopmentRemoved.type]: (state, action) => {
    state.toDevelop = state.toDevelop.filter((product) => product !== action.payload)
  },
  // Send product development
  [productDevelopmentSendRequested.type]: (state) => {
    state.sendProductDevelopmentLoading = true
    state.sendProductDevelopmentSuccess = false
  },
  [productDevelopmentSendReceived.type]: (state, action) => {
    state.sendProductDevelopmentLoading = false
    state.sendProductDevelopmentSuccess = true
    state.data = action.payload.data
    state.toDevelop = []
  },
  [productDevelopmentSendRequestFailed.type]: (state, action) => {
    state.sendProductDevelopmentLoading = false
    state.sendProductDevelopmentSuccess = false
    toast.error(action.payload.message)
  }
})

export default productDevelopmentReducer

// PUBLIC ACTIONS

export const loadProductDevelopment = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/product_development`,
      onStart: productDevelopmentRequested.type,
      onSuccess: productDevelopmentReceived.type,
      onError: productDevelopmentRequestFailed.type
    })
  )
}

export const addProductDevelopment = (productType) => productDevelopmentAdded(productType)

export const removeProductDevelopment = (productType) => productDevelopmentRemoved(productType)

export const sendProductDevelopment = () => (dispatch, getState) => {
  const state = getState()
  const id = gameIdSelector(state)
  const productTypes = state.productDevelopment.toDevelop

  dispatch(
    apiCallBegan({
      url: `/games/${id}/product_development/develop`,
      method: 'patch',
      data: { product_types: productTypes },
      onStart: productDevelopmentSendRequested.type,
      onSuccess: productDevelopmentSendReceived.type,
      onError: productDevelopmentSendRequestFailed.type
    })
  )
}

export const fireRawMaterialEmployee = (productType) => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/product_development/fire_raw_material_employee`,
      method: 'post',
      data: { product_type: productType },
      onStart: fireRawMaterialEmployeeRequested.type,
      onSuccess: fireRawMaterialEmployeeReceived.type,
      onError: fireRawMaterialEmployeeRequestFailed.type
    })
  )
}

export const fireProductEmployee = (productType) => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/product_development/fire_product_employee`,
      method: 'post',
      data: { product_type: productType },
      onStart: fireProductEmployeeRequested.type,
      onSuccess: fireProductEmployeeReceived.type,
      onError: fireProductEmployeeRequestFailed.type
    })
  )
}

export const fireProcurementEmployee = (productType) => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/product_development/fire_procurement_employee`,
      method: 'post',
      data: { product_type: productType },
      onStart: fireProcurementEmployeeRequested.type,
      onSuccess: fireProcurementEmployeeReceived.type,
      onError: fireProcurementEmployeeRequestFailed.type
    })
  )
}

export const hireProcurementEmployee = (productType) => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/product_development/hire_procurement_employee`,
      method: 'post',
      data: { product_type: productType },
      onStart: hireProcurementEmployeeRequested.type,
      onSuccess: hireProcurementEmployeeReceived.type,
      onError: hireProcurementEmployeeRequestFailed.type
    })
  )
}
