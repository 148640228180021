import React, { lazy, Suspense, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ROUTES from 'constants/routes'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { loadUserInfo, cleanUserInfo } from 'store/user/info'

import ScrollToTop from 'components/Landing/ScrollToTop'
import Login from 'components/Login'
import Toast from 'components/Shared/Toast'
import LoadingDots from 'components/Shared/LoadingDots'
import PrivateRoute from './Shared/PrivateRoute'
import Facilitators from './Facilitators/Facilitators'

const MainBoard = lazy(() => import('components/MainBoard'))
const Landing = lazy(() => import('components/Landing'))

function App() {
  const dispatch = useDispatch()

  const loggedIn = useSelector((store) => store.auth.loggedIn)

  useEffect(() => {
    if (loggedIn) {
      dispatch(loadUserInfo())
    }

    return () => {
      dispatch(cleanUserInfo())
    }
  }, [loggedIn])

  return (
    <Router basename="/">
      <Toast />
      <ScrollToTop>
        <Switch>
          <Route path={ROUTES.APP_URL_LOGIN}>
            <Login />
          </Route>
          <PrivateRoute path={ROUTES.APP_URL_FACILITATORS}>
            <Facilitators />
          </PrivateRoute>
          <PrivateRoute path={ROUTES.APP_URL_HOME}>
            <Suspense
              fallback={
                <div className="loading-page">
                  <LoadingDots />
                </div>
              }
            >
              <MainBoard />
            </Suspense>
          </PrivateRoute>
          <Suspense fallback={<div />}>
            <Landing />
          </Suspense>
        </Switch>
      </ScrollToTop>
    </Router>
  )
}

export default App
