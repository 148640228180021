import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

const currentIncomeStatementsRequested = createAction('incomeStatements/currentRequested')
const currentIncomeStatementsReceived = createAction('incomeStatements/currentReceived')
const currentIncomeStatementsFailed = createAction('incomeStatements/currentFailed')

const incomeStatementsRequested = createAction('incomeStatements/requested')
const incomeStatementsReceived = createAction('incomeStatements/received')
const incomeStatementsFailed = createAction('incomeStatements/failed')

const taxesCalculationRequested = createAction('taxesCalculation/requested')
const taxesCalculationReceived = createAction('taxesCalculation/received')
const taxesCalculationFailed = createAction('taxesCalculation/failed')

const waitCalculateBalanceSheetRequested = createAction('incomeStatements/waitCalculateRequested')
const waitCalculateBalanceSheetReceived = createAction('incomeStatements/waitCalculateReceived')
const waitCalculateBalanceSheetFailed = createAction('incomeStatements/waitCalculateFailed')

// REDUCER
export const initialState = {
  loading: false,
  loadingTaxes: false,
  loadingCurrent: false,
  loadingCalculate: false,
  waitCalculateLoading: false,
  items: [],
  current: null,
  success: false,
  currentSuccess: false,
  taxesSuccess: false,
  waitCalculateSuccess: false
}

// employees salaries

const incomeStatementsReducer = createReducer(initialState, {
  // current
  [currentIncomeStatementsRequested.type]: (state) => {
    state.loadingCurrent = true
    state.currentSuccess = false
  },
  [currentIncomeStatementsReceived.type]: (state, action) => {
    state.loadingCurrent = false
    state.currentSuccess = true
    state.current = action.payload.data
  },
  [currentIncomeStatementsFailed.type]: (state, action) => {
    state.loadingCurrent = false
    toast.error(action.payload.message)
  },
  // items
  [incomeStatementsRequested.type]: (state) => {
    state.loading = true
    state.success = false
  },
  [incomeStatementsReceived.type]: (state, action) => {
    state.loading = false
    state.success = true
    state.items = action.payload.data
  },
  [incomeStatementsFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  // items
  [taxesCalculationRequested.type]: (state) => {
    state.loadingTaxes = true
    state.taxesSuccess = false
  },
  [taxesCalculationReceived.type]: (state) => {
    state.loadingTaxes = false
    state.taxesSuccess = true
  },
  [taxesCalculationFailed.type]: (state, action) => {
    state.loadingTaxes = false
    toast.error(action.payload.message)
  },
  // Wait calculate
  [waitCalculateBalanceSheetRequested.type]: (state) => {
    state.waitCalculateLoading = true
    state.waitCalculateSuccess = false
  },
  [waitCalculateBalanceSheetReceived.type]: (state) => {
    state.waitCalculateLoading = false
    state.waitCalculateSuccess = true
  },
  [waitCalculateBalanceSheetFailed.type]: (state, action) => {
    state.waitCalculateLoading = false
    state.waitCalculateSuccess = false
    toast.error(action.payload.message)
  }
})

export default incomeStatementsReducer

// employees salaries
export const loadCurrentIncomeStatement = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `games/${id}/income_statements/current`,
      onStart: currentIncomeStatementsRequested.type,
      onSuccess: currentIncomeStatementsReceived.type,
      onError: currentIncomeStatementsFailed.type
    })
  )
}

export const loadIncomeStatements = () => (dispatch, getState) => {
  const incomeStatementState = getState()
  const id = gameIdSelector(incomeStatementState)
  dispatch(
    apiCallBegan({
      url: `games/${id}/income_statements/`,
      onStart: incomeStatementsRequested.type,
      onSuccess: incomeStatementsReceived.type,
      onError: incomeStatementsFailed.type
    })
  )
}

export const calculateTaxes = () => (dispatch, getState) => {
  const incomeStatementState = getState()
  const id = gameIdSelector(incomeStatementState)
  dispatch(
    apiCallBegan({
      url: `games/${id}/income_statements/calculate_taxes`,
      method: 'POST',
      onStart: taxesCalculationRequested.type,
      onSuccess: taxesCalculationReceived.type,
      onError: taxesCalculationFailed.type
    })
  )
}

export const waitCalculateBalanceSheet = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `games/${id}/income_statements/wait_next_action`,
      method: 'POST',
      onStart: waitCalculateBalanceSheetRequested.type,
      onSuccess: waitCalculateBalanceSheetReceived.type,
      onError: waitCalculateBalanceSheetFailed.type
    })
  )
}
