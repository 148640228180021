import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// ACTIONS
const participantQuizRequested = createAction('participantQuiz/requested')
const participantQuizReceived = createAction('participantQuiz/received')
const participantQuizFailed = createAction('participantQuiz/requestFailed')

const participantQuizUpdateRequested = createAction('participantQuiz/updateRequested')
const participantQuizUpdateReceived = createAction('participantQuiz/updateReceived')
const participantQuizUpdateFailed = createAction('participantQuiz/updateFailed')

const introSet = createAction('participantQuiz/setIntro')
const businessPlanAndStrategySet = createAction('participantQuiz/setBusinessPlanAndStrategy')
const investmentCalculationSet = createAction('participantQuiz/setInvestmentCalculation')
const strategicEvaluationSet = createAction('participantQuiz/setStrategicEvaluation')
const conclusionSet = createAction('participantQuiz/setConclusion')

const participantQuizClean = createAction('participantQuiz/clean')

// REDUCER
export const initialState = {
  data: {},
  loading: false,
  loadingUpdate: false,
  requestParticipantQuizSuccess: false,
  updateParticipantQuizSuccess: false
}

const participantQuizReducer = createReducer(initialState, {
  [participantQuizRequested.type]: (state) => {
    state.loading = true
    state.requestParticipantQuizSuccess = false
  },
  [participantQuizReceived.type]: (state, action) => {
    state.loading = false
    state.data = action.payload.data
    state.requestParticipantQuizSuccess = true
  },
  [participantQuizFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  [participantQuizUpdateRequested.type]: (state) => {
    state.loadingUpdate = true
    state.updateParticipantQuizSuccess = false
  },
  [participantQuizUpdateReceived.type]: (state) => {
    state.loadingUpdate = false
    state.updateParticipantQuizSuccess = true
  },
  [participantQuizUpdateFailed.type]: (state, action) => {
    state.loadingUpdate = false
    toast.error(action.payload.message)
  },
  [introSet.type]: (state, action) => {
    state.data.intro = action.payload
  },
  [businessPlanAndStrategySet.type]: (state, action) => {
    state.data.business_plan_and_strategy = action.payload
  },
  [investmentCalculationSet.type]: (state, action) => {
    state.data.investment_calculation = action.payload
  },
  [strategicEvaluationSet.type]: (state, action) => {
    state.data.strategic_evaluation = action.payload
  },
  [conclusionSet.type]: (state, action) => {
    state.data.conclusion = action.payload
  },
  [participantQuizClean.type]: () => initialState
})

export default participantQuizReducer

// Public actions
export const loadParticipantQuiz = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)

  dispatch(
    apiCallBegan({
      url: `/games/${id}/participant_quizzes`,
      onStart: participantQuizRequested.type,
      onSuccess: participantQuizReceived.type,
      onError: participantQuizFailed.type
    })
  )
}

export const updateParticipantQuiz = (data) => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)

  dispatch(
    apiCallBegan({
      url: `/games/${id}/participant_quizzes/update`,
      method: 'post',
      data,
      onStart: participantQuizUpdateRequested.type,
      onSuccess: participantQuizUpdateReceived.type,
      onError: participantQuizUpdateFailed.type
    })
  )
}

export const setIntro = (data) => (dispatch) => dispatch(introSet(data))
export const setBusinessPlanAndStrategy = (data) => (dispatch) =>
  dispatch(businessPlanAndStrategySet(data))
export const setInvestmentCalculation = (data) => (dispatch) =>
  dispatch(investmentCalculationSet(data))
export const setStrategicEvaluation = (data) => (dispatch) => dispatch(strategicEvaluationSet(data))
export const setConclusion = (data) => (dispatch) => dispatch(conclusionSet(data))

export const cleanParticipantQuiz = () => (dispatch) => dispatch(participantQuizClean())
