import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector, gameAiSelector } from 'store/user/selectors'

const graphicsRequested = createAction('graphics/requested')
const graphicsReceived = createAction('graphics/received')
const graphicsFailed = createAction('graphics/failed')

const graphicsJoinRequested = createAction('graphics/joinRequested')
const graphicsJoinReceived = createAction('graphics/joinReceived')
const graphicsJoinFailed = createAction('graphics/joinFailed')

const graphicsCurrentRequested = createAction('graphics/currentRequested')
const graphicsCurrentReceived = createAction('graphics/currentReceived')
const graphicsCurrentFailed = createAction('graphics/currentFailed')

const graphicsFinishRequested = createAction('graphics/finishRequested')
const graphicsFinishReceived = createAction('graphics/finishReceived')
const graphicsFinishFailed = createAction('graphics/finishFailed')

const currentTeamGraphicUpdate = createAction('graphics/currentTeamGraphicUpdate')

const graphicsClean = createAction('graphics/clean')

// REDUCER
export const initialState = {
  loading: false,
  success: false,
  joinSuccess: false,
  currentSuccess: false,
  finishSuccess: false,
  info: null,
  currentTeamGraphic: 'market_position'
}

const graphicsReducer = createReducer(initialState, {
  [graphicsRequested.type]: (state) => {
    state.loading = true
    state.success = false
  },
  [graphicsReceived.type]: (state, action) => {
    state.loading = false
    state.success = true
    state.info = action.payload.data
  },
  [graphicsFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  [graphicsJoinRequested.type]: (state) => {
    state.loading = true
    state.joinSuccess = false
  },
  [graphicsJoinReceived.type]: (state) => {
    state.loading = false
    state.joinSuccess = true
  },
  [graphicsJoinFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  [graphicsCurrentRequested.type]: (state) => {
    state.loading = true
    state.currentSuccess = false
  },
  [graphicsCurrentReceived.type]: (state) => {
    state.loading = false
    state.currentSuccess = true
  },
  [graphicsCurrentFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  [graphicsFinishRequested.type]: (state) => {
    state.loading = true
    state.finishSuccess = false
  },
  [graphicsFinishReceived.type]: (state) => {
    state.loading = false
    state.finishSuccess = true
  },
  [graphicsFinishFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  [currentTeamGraphicUpdate.type]: (state, action) => {
    state.currentTeamGraphic = action.payload
  },
  [graphicsClean.type]: () => initialState
})

export default graphicsReducer

export const loadGraphics = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `games/${id}/graphics`,
      onStart: graphicsRequested.type,
      onSuccess: graphicsReceived.type,
      onError: graphicsFailed.type
    })
  )
}

export const joinGraphics = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `games/${id}/graphics/join`,
      method: 'post',
      onStart: graphicsJoinRequested.type,
      onSuccess: graphicsJoinReceived.type,
      onError: graphicsJoinFailed.type
    })
  )
}

export const setCurrentGraphic = (selectedGraphic) => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `games/${id}/graphics/current`,
      method: 'put',
      data: { current: selectedGraphic },
      onStart: graphicsCurrentRequested.type,
      onSuccess: graphicsCurrentReceived.type,
      onError: graphicsCurrentFailed.type
    })
  )
}

export const finishGraphics = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())
  const aiGame = gameAiSelector(getState())
  dispatch(graphicsClean())

  const endpoint = `games/${id}/graphics/${aiGame ? 'finish_team' : 'finish'}`

  dispatch(
    apiCallBegan({
      url: endpoint,
      method: 'post',
      onStart: graphicsFinishRequested.type,
      onSuccess: graphicsFinishReceived.type,
      onError: graphicsFinishFailed.type
    })
  )
}

export const setCurrentTeamGraphic = (selectedGraphic) => (dispatch) => {
  dispatch(currentTeamGraphicUpdate(selectedGraphic))
}

export const cleanGraphics = () => (dispatch) => dispatch(graphicsClean())
