import i18n from 'i18n'

import { PRODUCT_TYPES } from 'constants/productTypes'
import { toSnakeCase } from 'utils/transformText'

export default function verificationMarketing(data, markets) {
  const deployedEmployees = data.filter((market) => market.deployed_sales_employees > 0)

  const toReturn = {
    showAlert: false,
    description: ''
  }

  if (deployedEmployees.length === 0) return toReturn

  const verificationToDeployed = deployedEmployees.filter((market) =>
    PRODUCT_TYPES.every((product) => market[`${toSnakeCase(product)}_communication_points`] === 0)
  )

  if (verificationToDeployed.length === 0) return toReturn

  const namesMarkets = verificationToDeployed
    .map((m) => {
      const { slug_name } = markets.find((n) => n.market_id === m.market_id)
      return i18n.t(`markets.${slug_name}`)
    })
    .join(', ')

  const text = `${i18n.t('marketing_board.alert.description_first')} ${namesMarkets} ${i18n.t(
    'marketing_board.alert.description_last'
  )}`
  toReturn.showAlert = true
  toReturn.description = text

  return toReturn
}
