import { createAction, createReducer } from '@reduxjs/toolkit'
import { MACHINE_STATES } from 'constants/machines'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'
import i18n from '../../i18n'

// ACTIONS
const productionRequested = createAction('production/requested')
const productionReceived = createAction('production/received')
const productionRequestFailed = createAction('production/requestFailed')

const productionUpdateRequested = createAction('production/updateRequested')
const productionUpdateRequestFailed = createAction('production/updateRequestFailed')
const productionUpdateReceived = createAction('production/updateReceived')

const productionInvestmentFinishRequested = createAction('production/investmentFinishRequested')
const productionInvestmentFinishRequestFailed = createAction(
  'production/investmentFinishRequestFailed'
)
const productionInvestmentFinishReceived = createAction('production/investmentFinishReceived')

const productionInvestmentUpdateRequested = createAction('production/investmentUpdateRequested')
const productionInvestmentUpdateRequestFailed = createAction(
  'production/investmentUpdateRequestFailed'
)
const productionInvestmentUpdateReceived = createAction('production/investmentUpdateReceived')

// Product changeover
const productionInvestmentProductChangeoverReceived = createAction(
  'production/investmentProductChangeoverReceived'
)

// Buy machine
const productionInvestmentBuyMachineReceived = createAction(
  'production/investmentBuyMachineReceived'
)

const cleanInvestmentSuccess = createAction('production/cleanInvestmentSuccess')

const payMaintenanceRequested = createAction('maintenance/requested')
const payMaintenanceReceived = createAction('maintenance/received')
const payMaintenanceRequestFailed = createAction('maintenance/requestFailed')

const payRentAndStorageRequested = createAction('rentAndStorage/requested')
const payRentAndStorageReceived = createAction('rentAndStorage/received')
const payRentAndStorageFailed = createAction('rentAndStorage/requestFailed')

const calculateDepreciationRequested = createAction('calculateDepreciation/requested')
const calculateDepreciationReceived = createAction('calculateDepreciation/received')
const calculateDepreciationFailed = createAction('calculateDepreciation/requestFailed')

const startNewProductionRequested = createAction('startNewProduction/requested')
const startNewProductionReceived = createAction('startNewProduction/received')
const startNewProductionFailed = createAction('startNewProduction/requestFailed')
const addMachineToStartProduction = createAction('startNewProduction/addMachine')
const stopMachineToStartProduction = createAction('startNewProduction/stopMachine')

const showAlertIdleMachines = createAction('startNewProduction/showAlert')
const showAlertRawMaterials = createAction('startNewProduction/showAlertRaw')

const cleanStartNewProduction = createAction('startNewProduction/clean')
const restartNewProduction = createAction('startNewProduction/restart')

// REDUCER
export const initialState = {
  data: null,
  loading: false,
  updateLoading: false,
  success: false,
  updateSuccess: false,
  // Investment
  finishSuccess: false,
  investmentLoading: false,
  investmentSuccess: false,
  payMaintenanceLoading: false,
  payMaintenanceSuccess: false,
  payRentAndStorageLoading: false,
  payRentAndStorageSuccess: false,
  calculateDepreciationLoading: false,
  calculateDepreciationSuccess: false,
  // Production
  startNewProductionLoading: false,
  startNewProductionSuccess: false,
  newProduction: [],
  showAlertIdleMachines: false,
  showAlertRawMaterials: false
}

const productionReducer = createReducer(initialState, {
  [productionRequested.type]: (state) => {
    state.loading = true
    state.success = false
  },
  [productionReceived.type]: (state, action) => {
    state.loading = false
    state.success = true
    state.data = action.payload.data
  },
  [productionRequestFailed.type]: (state, action) => {
    state.loading = false
    state.success = false
    toast.error(action.payload.message)
  },
  // Update production
  [productionUpdateRequested.type]: (state) => {
    state.updateLoading = true
    state.updateSuccess = false
  },
  [productionUpdateReceived.type]: (state, action) => {
    state.updateLoading = false
    state.updateSuccess = true
    state.data = action.payload.data
  },
  [productionUpdateRequestFailed.type]: (state, action) => {
    state.updateLoading = false
    state.updateSuccess = false
    toast.error(action.payload.message)
  },
  // Finish investment
  [productionInvestmentFinishRequested.type]: (state) => {
    state.investmentLoading = true
    state.finishSuccess = false
  },
  [productionInvestmentFinishReceived.type]: (state, action) => {
    state.investmentLoading = false
    state.finishSuccess = true
    state.data = action.payload.data
  },
  [productionInvestmentFinishRequestFailed.type]: (state, action) => {
    state.investmentLoading = false
    state.finishSuccess = false
    toast.error(action.payload.message)
  },
  [productionInvestmentUpdateRequested.type]: (state) => {
    state.investmentLoading = true
    state.investmentSuccess = false
  },
  [productionInvestmentUpdateReceived.type]: (state, action) => {
    state.investmentLoading = false
    state.investmentSuccess = true
    state.data = action.payload.data
  },
  [productionInvestmentUpdateRequestFailed.type]: (state, action) => {
    state.investmentLoading = false
    state.investmentSuccess = false
    toast.error(action.payload.message)
  },
  [productionInvestmentProductChangeoverReceived.type]: (state, action) => {
    state.investmentLoading = false
    state.investmentSuccess = true
    state.data = action.payload.data
    toast.success(i18n.t('investment.product_changeover.success'))
  },
  [productionInvestmentBuyMachineReceived.type]: (state, action) => {
    state.investmentLoading = false
    state.investmentSuccess = true
    state.data = action.payload.data
    toast.success(i18n.t('investment.buy_machine.success'))
  },
  [cleanInvestmentSuccess.type]: (state) => {
    state.investmentSuccess = false
  },
  // pay maintenance
  [payMaintenanceRequested.type]: (state) => {
    state.payMaintenanceLoading = true
    state.payMaintenanceSuccess = false
  },
  [payMaintenanceReceived.type]: (state) => {
    state.payMaintenanceLoading = false
    state.payMaintenanceSuccess = true
  },
  [payMaintenanceRequestFailed.type]: (state, action) => {
    state.payMaintenanceLoading = false
    state.payMaintenanceSuccess = false
    toast.error(action.payload.message)
  },
  // pay rent and storage
  [payRentAndStorageRequested.type]: (state) => {
    state.payRentAndStorageLoading = true
    state.payRentAndStorageSuccess = false
  },
  [payRentAndStorageReceived.type]: (state) => {
    state.payRentAndStorageLoading = false
    state.payRentAndStorageSuccess = true
  },
  [payRentAndStorageFailed.type]: (state, action) => {
    state.payRentAndStorageLoading = false
    state.payRentAndStorageSuccess = false
    toast.error(action.payload.message)
  },

  // calculate machinery depreciation
  [calculateDepreciationRequested.type]: (state) => {
    state.calculateDepreciationLoading = true
    state.calculateDepreciationSuccess = false
  },
  [calculateDepreciationReceived.type]: (state, action) => {
    state.calculateDepreciationLoading = false
    state.calculateDepreciationSuccess = true
    state.data = action.payload.data
  },
  [calculateDepreciationFailed.type]: (state, action) => {
    state.calculateDepreciationLoading = false
    state.calculateDepreciationSuccess = false
    toast.error(action.payload.message)
  },
  // Start new production
  [startNewProductionRequested.type]: (state) => {
    state.startNewProductionLoading = true
    state.startNewProductionSuccess = false
  },
  [startNewProductionReceived.type]: (state, action) => {
    state.startNewProductionLoading = false
    state.startNewProductionSuccess = true
    state.newProduction = []
    state.data = action.payload.data
    state.showAlertIdleMachines = false
  },
  [startNewProductionFailed.type]: (state, action) => {
    state.startNewProductionLoading = false
    state.startNewProductionSuccess = false
    state.newProduction = []
    toast.error(action.payload.message)
  },
  [addMachineToStartProduction.type]: (state, action) => {
    state.newProduction = [...state.newProduction, action.payload]
  },
  [stopMachineToStartProduction.type]: (state, action) => {
    state.newProduction = state.newProduction.filter((machine) => machine !== action.payload)
  },
  [showAlertIdleMachines.type]: (state, action) => {
    state.showAlertIdleMachines = action.payload
  },
  [showAlertRawMaterials.type]: (state, action) => {
    state.showAlertRawMaterials = action.payload
  },
  [cleanStartNewProduction.type]: (state) => {
    state.newProduction = []
  },
  [restartNewProduction.type]: (state, action) => {
    state.newProduction = action.payload
  }
})

export default productionReducer

// PUBLIC ACTIONS
export const loadProduction = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/production`,
      onStart: productionRequested.type,
      onSuccess: productionReceived.type,
      onError: productionRequestFailed.type
    })
  )
}

export const updateProduction = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/production/update_production`,
      method: 'patch',
      onStart: productionUpdateRequested.type,
      onSuccess: productionUpdateReceived.type,
      onError: productionUpdateRequestFailed.type
    })
  )
}

// Investments
export const finishInvestment = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/investments/finish`,
      method: 'post',
      onStart: productionInvestmentFinishRequested.type,
      onSuccess: productionInvestmentFinishReceived.type,
      onError: productionInvestmentFinishRequestFailed.type
    })
  )
}

export const buyPlant = (plantId) => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  const data = {
    plant_id: plantId
  }

  dispatch(
    apiCallBegan({
      url: `/games/${id}/investments/buy_plant`,
      method: 'post',
      data,
      onStart: productionInvestmentUpdateRequested.type,
      onSuccess: productionInvestmentUpdateReceived.type,
      onError: productionInvestmentUpdateRequestFailed.type
    })
  )
}

export const rentPlant = (plantId) => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  const data = {
    plant_id: plantId
  }

  dispatch(
    apiCallBegan({
      url: `/games/${id}/investments/rent_plant`,
      method: 'post',
      data,
      onStart: productionInvestmentUpdateRequested.type,
      onSuccess: productionInvestmentUpdateReceived.type,
      onError: productionInvestmentUpdateRequestFailed.type
    })
  )
}

export const sellPlant = (plantId) => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  const data = {
    plant_id: plantId
  }

  dispatch(
    apiCallBegan({
      url: `/games/${id}/investments/sell_and_rent_plant`,
      method: 'post',
      data,
      onStart: productionInvestmentUpdateRequested.type,
      onSuccess: productionInvestmentUpdateReceived.type,
      onError: productionInvestmentUpdateRequestFailed.type
    })
  )
}

export const buyAndInstallMachine = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  const { selectedPlant, selectedProductType, selectedMachineType } = currentState.investments

  if (!selectedPlant || !selectedMachineType || !selectedProductType)
    return toast.error(i18n.t('production.errors.not_product_type'))

  const data = {
    plant_id: selectedPlant,
    machine_type: selectedMachineType,
    product_type: selectedProductType
  }

  return dispatch(
    apiCallBegan({
      url: `/games/${id}/investments/buy_and_install_machine`,
      method: 'POST',
      data,
      onStart: productionInvestmentUpdateRequested.type,
      onSuccess: productionInvestmentBuyMachineReceived.type,
      onError: productionInvestmentUpdateRequestFailed.type
    })
  )
}

export const productChangeover = (productType) => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  const { selectedMachine } = currentState.investments

  const data = {
    machine_id: selectedMachine,
    product_type: productType
  }

  return dispatch(
    apiCallBegan({
      url: `/games/${id}/investments/product_changeover`,
      method: 'post',
      data,
      onStart: productionInvestmentUpdateRequested.type,
      onSuccess: productionInvestmentProductChangeoverReceived.type,
      onError: productionInvestmentUpdateRequestFailed.type
    })
  )
}

export const sellMachine = (machineId) => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  const data = {
    machine_id: machineId
  }

  return dispatch(
    apiCallBegan({
      url: `/games/${id}/investments/sell_machine`,
      method: 'post',
      data,
      onStart: productionInvestmentUpdateRequested.type,
      onSuccess: productionInvestmentUpdateReceived.type,
      onError: productionInvestmentUpdateRequestFailed.type
    })
  )
}

export const payMaintenanceCosts = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/production/pay_machines_maintenance`,
      method: 'post',
      onStart: payMaintenanceRequested.type,
      onSuccess: payMaintenanceReceived.type,
      onError: payMaintenanceRequestFailed.type
    })
  )
}

export const payRentAndStorageCosts = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/production/pay_rent_and_storage`,
      method: 'post',
      onStart: payRentAndStorageRequested.type,
      onSuccess: payRentAndStorageReceived.type,
      onError: payRentAndStorageFailed.type
    })
  )
}

export const calculateMachineryDepreciation = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/production/machines_depreciation`,
      method: 'post',
      onStart: calculateDepreciationRequested.type,
      onSuccess: calculateDepreciationReceived.type,
      onError: calculateDepreciationFailed.type
    })
  )
}

// Start production
export const startNewProduction = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  const { newProduction } = currentState.production

  const data = {
    new_production: newProduction
  }

  dispatch(
    apiCallBegan({
      url: `/games/${id}/production/start_new_production`,
      method: 'post',
      data,
      onStart: startNewProductionRequested.type,
      onSuccess: startNewProductionReceived.type,
      onError: startNewProductionFailed.type
    })
  )
}

export const reloadStartNewProduction = () => (dispatch) => dispatch(cleanStartNewProduction())

export const startMachine = (machineId) => (dispatch) =>
  dispatch(addMachineToStartProduction(machineId))

export const stopMachine = (machineId) => (dispatch) =>
  dispatch(stopMachineToStartProduction(machineId))

export const setShowAlertRawMaterials = (value) => (dispatch) =>
  dispatch(showAlertRawMaterials(value))

export const setShowAlertIdleMachines = (value) => (dispatch) =>
  dispatch(showAlertIdleMachines(value))

export const loadNewProduction = () => (dispatch, getState) => {
  const {
    newProduction,
    data: { plants }
  } = getState().production

  const { data: rawMaterials } = getState().rawMaterialsInventory
  const machines = plants.map((plant) => plant.machines).flat()

  const availableRawMaterials = Object.values(rawMaterials)
  const machinesToDeploy = machines.filter((machine) => newProduction.includes(machine.id))

  const newNewProduction = availableRawMaterials
    .map((rM, i) => {
      const machinesPerProduct = machinesToDeploy
        .filter((machine) => machine.product_type === Object.keys(rawMaterials)[i])
        .map((machine) => machine.id)
      const difference = machinesPerProduct.length - rM
      return difference > 0 ? machinesPerProduct.slice(0, -difference) : machinesPerProduct
    })
    .flat()

  dispatch(setShowAlertRawMaterials(false))
  dispatch(restartNewProduction(newNewProduction))
  dispatch(startNewProduction())
}

export const idleMachines = () => (dispatch, getState) => {
  const {
    newProduction,
    data: { plants }
  } = getState().production

  const { data: rawMaterials } = getState().rawMaterialsInventory

  const machines = plants.map((plant) => plant.machines).flat()

  const hasIdleMachines = machines.some(
    (machine) => machine.status === MACHINE_STATES.installed && !newProduction.includes(machine.id)
  )

  const availableRawMaterials = Object.values(rawMaterials)
  const machinesToDeploy = machines.filter((machine) => newProduction.includes(machine.id))

  const cannotStartMachine = availableRawMaterials.some(
    (rM, i) =>
      machinesToDeploy.filter((machine) => machine.product_type === Object.keys(rawMaterials)[i])
        .length -
        rM >
      0
  )

  if (cannotStartMachine) {
    dispatch(setShowAlertRawMaterials(true))
  } else if (hasIdleMachines) {
    dispatch(setShowAlertIdleMachines(true))
  } else {
    dispatch(startNewProduction())
  }
}

export const reloadInvestmentSuccess = () => (dispatch) => dispatch(cleanInvestmentSuccess())
