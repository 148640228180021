import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// ACTIONS
const marketStrategyRequested = createAction('marketStrategy/requested')
const marketStrategyRequestFailed = createAction('marketStrategy/requestFailed')
const marketStrategyReceived = createAction('marketStrategy/received')

const addSelectedDeployMarket = createAction('marketStrategy/addSelectedDeployMarket')
const deleteSelectedDeploymarket = createAction('marketStrategy/deleteSelectedDeploymarket')

const marketStrategyDeployRequested = createAction('marketStrategy/deployRequested')
const marketStrategyDeployReceived = createAction('marketStrategy/deployReceived')
const marketStrategyDeployFailed = createAction('marketStrategy/deployFailed')

// REDUCER
export const initialState = {
  items: [],
  loading: false,
  success: false,
  deployMarketLoading: false,
  deployMarketSuccess: false,
  selectedDeployMarket: []
}

const marketStrategyListReducer = createReducer(initialState, {
  // Load market strategy
  [marketStrategyRequested.type]: (state) => {
    state.loading = true
  },
  [marketStrategyReceived.type]: (state, action) => {
    state.loading = false
    state.success = true
    state.items = action.payload.data
    state.selectedDeployMarket = []
  },
  [marketStrategyRequestFailed.type]: (state, action) => {
    state.loading = false
    state.success = false
    toast.error(action.payload.message)
  },
  // Deploy market
  [marketStrategyDeployRequested.type]: (state) => {
    state.deployMarketLoading = true
  },
  [marketStrategyDeployReceived.type]: (state, action) => {
    state.deployMarketLoading = false
    state.deployMarketSuccess = true
    state.items = action.payload.data
    state.selectedDeployMarket = []
  },
  [marketStrategyDeployFailed.type]: (state, action) => {
    state.deployMarketLoading = false
    state.deployMarketSuccess = false
    toast.error(action.payload.message)
  },
  // Select deploy market
  [addSelectedDeployMarket.type]: (state, action) => {
    state.selectedDeployMarket = [...state.selectedDeployMarket, action.payload]
  },
  [deleteSelectedDeploymarket.type]: (state, action) => {
    state.selectedDeployMarket = state.selectedDeployMarket.filter((id) => id !== action.payload)
  }
})

export default marketStrategyListReducer

// PUBLIC ACTIONS
export const loadMarketStrategy = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/markets`,
      onStart: marketStrategyRequested.type,
      onSuccess: marketStrategyReceived.type,
      onError: marketStrategyRequestFailed.type
    })
  )
}

export const selectDeployMarket = (marketId) => (dispatch) => {
  dispatch(addSelectedDeployMarket(marketId))
}

export const unselectDeployMarket = (marketId) => (dispatch) => {
  dispatch(deleteSelectedDeploymarket(marketId))
}

export const deployMarket = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())
  const { selectedDeployMarket } = getState().marketStrategy

  dispatch(
    apiCallBegan({
      url: `/games/${id}/markets/deploy`,
      method: 'post',
      data: { markets_to_deploy: selectedDeployMarket },
      onStart: marketStrategyDeployRequested.type,
      onSuccess: marketStrategyDeployReceived.type,
      onError: marketStrategyDeployFailed.type
    })
  )
}
