import { combineReducers } from 'redux'

import auth from './auth'
import balanceSheet from './balanceSheet'
import calculations from './calculations'
import cashAlert from './cashAlert'
import cashFlow from './cashFlow'
import employees from './employees'
import decisionAids from './decisionAids'
import graphics from './graphics'
import incomeStatements from './incomeStatements'
import user from './user'
import loans from './loans'
import marketingBoard from './marketingBoard'
import marketRound from './marketRound'
import marketStrategy from './marketStrategy'
import orders from './orders'
import procurement from './procurement'
import productDevelopment from './productDevelopment'
import production from './production'
import productQualityStrategy from './productQualityStrategy'
import rawMaterialsInventory from './rawMaterialsInventory'
import salesStrategy from './salesStrategy'
import step from './step'
import trainingIndex from './trainingIndex'
import investments from './investments'
import dashboard from './dashboard'
import participantQuiz from './participantQuiz'

const rootReducer = combineReducers({
  auth,
  balanceSheet,
  calculations,
  cashAlert,
  cashFlow,
  employees,
  dashboard,
  decisionAids,
  graphics,
  incomeStatements,
  investments,
  loans,
  marketingBoard,
  marketRound,
  marketStrategy,
  orders,
  participantQuiz,
  procurement,
  productDevelopment,
  production,
  productQualityStrategy,
  rawMaterialsInventory,
  salesStrategy,
  step,
  trainingIndex,
  user
})

export default rootReducer
