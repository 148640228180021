import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Logo from 'assets/icons/logo.png'
import CloseButton from 'components/Shared/CloseButton'

function GameCard({ game, selectGameHandler, selectTeamHandler, selectedGameId }) {
  const { t } = useTranslation()
  const activeGame = selectedGameId === game.id

  const activeGameClass = () => {
    if (selectedGameId === null) return ''

    return activeGame ? 'active' : 'hidden'
  }

  function goBack(event) {
    event.stopPropagation()
    selectGameHandler(null)
    selectTeamHandler(null)
  }

  return (
    <button
      className={`btn facilitators__game-card ${activeGameClass()}`}
      type="button"
      onClick={() => selectGameHandler(game.id)}
    >
      <img src={Logo} alt="Logo" className="facilitators__game-logo" />
      <div className="facilitators__game-title mb-1">{game.name}</div>
      {game.start_date && (
        <div className="flex-row mb-1">
          <div className="facilitators__game-title">{t('facilitators.date')}:</div>
          <div className="facilitators__game-date">{game.start_date}</div>
        </div>
      )}
      {activeGame && (
        <>
          <CloseButton className="facilitators__game-close" handleClose={goBack} />
          <select
            onChange={(e) => selectTeamHandler(e.target.value)}
            className="facilitators__select"
          >
            <option>{t('facilitators.select_team')}</option>
            {game.teams.map((team) => (
              <option value={team.id}>{team.name}</option>
            ))}
          </select>
        </>
      )}
    </button>
  )
}

GameCard.propTypes = {
  game: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    start_date: PropTypes.string.isRequired,
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  selectGameHandler: PropTypes.func.isRequired,
  selectTeamHandler: PropTypes.func.isRequired,
  selectedGameId: PropTypes.number.isRequired
}

export default memo(GameCard)
