import i18n from '../i18n'

const BASE_URL = process.env.REACT_APP_API_URL
const WS_URL = process.env.REACT_APP_API_WS_URL
const REFRESH_TOKEN_URL = BASE_URL + '/oauth/token'
const DEFAULT_PARAMS = { lang: i18n.language }

const Api = {
  BASE_URL,
  REFRESH_TOKEN_URL,
  DEFAULT_PARAMS,
  WS_URL
}

export default Api
