import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// ACTIONS
const marketRoundRequested = createAction('marketRound/requested')
const marketRoundRequestFailed = createAction('marketRound/requestFailed')
const marketRoundReceived = createAction('marketRound/received')

const marketRoundJoinRequested = createAction('marketRound/joinRequested')
const marketRoundJoinRequestFailed = createAction('marketRound/joinRequestFailed')
const marketRoundJoinReceived = createAction('marketRound/joinReceived')

const addSelectedOrder = createAction('marketRound/addSelectedOrder')

const marketRoundSelectOrdersRequested = createAction('marketRound/selectOrdersRequested')
const marketRoundSelectOrdersReceived = createAction('marketRound/selectOrdersRequestFailed')
const marketRoundSelectOrdersRequestFailed = createAction('marketRound/selectOrdersReceived')

const showAlertOrders = createAction('marketRound/showAlert')

// REDUCER
export const initialState = {
  data: null,
  loading: false,
  joinLoading: false,
  selectOrdersloading: false,
  success: false,
  joinSuccess: false,
  selectedOrders: [],
  selectOrdersSuccess: false,
  currentSegment: null,
  showAlertOrders: false
}

const marketRoundListReducer = createReducer(initialState, {
  // Load market round
  [marketRoundRequested.type]: (state) => {
    state.loading = true
  },
  [marketRoundReceived.type]: (state, action) => {
    state.loading = false
    state.success = true
    state.data = action.payload.data
    state.currentSegment = action.payload.data
      ? action.payload.data.current_segment_round?.id
      : null
  },
  [marketRoundRequestFailed.type]: (state, action) => {
    state.loading = false
    state.success = false
    toast.error(action.payload.message)
  },
  // Join market round
  [marketRoundJoinRequested.type]: (state) => {
    state.joinSuccess = false
    state.joinLoading = true
  },
  [marketRoundJoinReceived.type]: (state) => {
    state.joinSuccess = true
    state.joinLoading = false
  },
  [marketRoundJoinRequestFailed.type]: (state, action) => {
    state.joinLoading = false
    state.joinSuccess = false
    toast.error(action.payload.message)
  },
  [addSelectedOrder.type]: (state, action) => {
    if (state.selectedOrders.find((order) => order === action.payload)) {
      state.selectedOrders = state.selectedOrders.filter((id) => id !== action.payload)
    } else {
      state.selectedOrders = [...state.selectedOrders, action.payload]
    }
  },
  [marketRoundSelectOrdersRequested.type]: (state) => {
    state.selectOrdersSuccess = false
    state.selectOrdersloading = true
  },
  [marketRoundSelectOrdersReceived.type]: (state) => {
    state.selectOrdersSuccess = true
    state.selectOrdersloading = false
    state.selectedOrders = []
    state.showAlertOrders = false
  },
  [marketRoundSelectOrdersRequestFailed.type]: (state, action) => {
    state.selectOrdersloading = false
    state.selectOrdersSuccess = false
    state.showAlertOrders = false
    toast.error(action.payload.message)
  },
  [showAlertOrders.type]: (state, action) => {
    state.showAlertOrders = action.payload
  }
})

export default marketRoundListReducer

// PUBLIC ACTIONS
export const loadMarketRound = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/market_rounds`,
      onStart: marketRoundRequested.type,
      onSuccess: marketRoundReceived.type,
      onError: marketRoundRequestFailed.type
    })
  )
}

export const joinMarketRound = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/market_rounds/join`,
      method: 'patch',
      onStart: marketRoundJoinRequested.type,
      onSuccess: marketRoundJoinReceived.type,
      onError: marketRoundJoinRequestFailed.type
    })
  )
}

export const selectOrder = (orderId) => (dispatch) => {
  dispatch(addSelectedOrder(orderId))
}

export const selectOrders = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  const { selectedOrders } = currentState.marketRound
  const { currentSegment } = currentState.marketRound

  const data = {
    segment_round_id: currentSegment,
    static_orders_ids: selectedOrders
  }

  dispatch(
    apiCallBegan({
      url: `/games/${id}/segment_rounds/select_orders`,
      method: 'post',
      data,
      onStart: marketRoundSelectOrdersRequested.type,
      onSuccess: marketRoundSelectOrdersReceived.type,
      onError: marketRoundSelectOrdersRequestFailed.type
    })
  )
}

export const setShowAlertOrders = (value) => (dispatch) => dispatch(showAlertOrders(value))

export const ordersSelected = () => (dispatch, getState) => {
  const { selectedOrders } = getState().marketRound

  if (selectedOrders.length > 0) {
    dispatch(selectOrders())
  } else {
    dispatch(setShowAlertOrders(true))
  }
}
