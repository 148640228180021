import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// ACTIONS
const decisionAidsRequested = createAction('decisionAids/requested')
const decisionAidsReceived = createAction('decisionAids/received')
const decisionAidsFailed = createAction('decisionAids/requestFailed')

const decisionAidsUpdateRequested = createAction('decisionAids/updateRequested')
const decisionAidsUpdateReceived = createAction('decisionAids/updateReceived')
const decisionAidsUpdateFailed = createAction('decisionAids/updateFailed')

const marketAnalysisSet = createAction('decisionAids/setMarketAnalysis')
const productionPlanSet = createAction('decisionAids/setProductionPlan')
const liquidityCalculationSet = createAction('decisionAids/setLiquidityCalculation')
const decisionAidsClean = createAction('decisionAids/clean')

// REDUCER
export const initialState = {
  data: {},
  loading: false,
  requestDecisionAidsSuccess: false,
  updateDecisionAidsSuccess: false
}

const decisionAidsReducer = createReducer(initialState, {
  [decisionAidsRequested.type]: (state) => {
    state.loading = true
    state.requestDecisionAidsSuccess = false
  },
  [decisionAidsReceived.type]: (state, action) => {
    state.loading = false
    state.data = action.payload.data
    state.requestDecisionAidsSuccess = true
  },
  [decisionAidsFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  [decisionAidsUpdateRequested.type]: (state) => {
    state.loading = true
    state.updateDecisionAidsSuccess = false
  },
  [decisionAidsUpdateReceived.type]: (state) => {
    state.loading = false
    state.updateDecisionAidsSuccess = true
  },
  [decisionAidsUpdateFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  [marketAnalysisSet.type]: (state, action) => {
    state.data.market_analysis = action.payload
  },
  [productionPlanSet.type]: (state, action) => {
    state.data.production_plan = action.payload
  },
  [liquidityCalculationSet.type]: (state, action) => {
    state.data.liquidity_calculation = action.payload
  },
  [decisionAidsClean.type]: () => initialState
})

export default decisionAidsReducer

// Public actions
export const loadDecisionAids = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `/games/${id}/decision_aids`,
      onStart: decisionAidsRequested.type,
      onSuccess: decisionAidsReceived.type,
      onError: decisionAidsFailed.type
    })
  )
}

export const updateDecisionAids = (data) => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `/games/${id}/decision_aids/update`,
      method: 'post',
      data,
      onStart: decisionAidsUpdateRequested.type,
      onSuccess: decisionAidsUpdateReceived.type,
      onError: decisionAidsUpdateFailed.type
    })
  )
}

export const setMarketAnalysis = (data) => (dispatch) => dispatch(marketAnalysisSet(data))
export const setProductionPlan = (data) => (dispatch) => dispatch(productionPlanSet(data))

export const setLiquidityCalculation = (data) => (dispatch) =>
  dispatch(liquidityCalculationSet(data))

export const cleanDecisionAids = () => (dispatch) => dispatch(decisionAidsClean())
