import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

const salariesRequested = createAction('employees/salariesRequested')
const salariesReceived = createAction('employees/salariesReceived')
const salariesFailed = createAction('employees/salariesFailed')

const salariesPayRequested = createAction('employees/salariesPayRequested')
const salariesPayReceived = createAction('employees/salariesPayReceived')
const salariesPayFailed = createAction('employees/salariesPayFailed')

// REDUCER
export const initialState = {
  loading: false,
  salariesPayLoading: false,
  salaries: {},
  salariesSuccess: false,
  salariesPaySuccess: false
}

// employees salaries

const employeesReducer = createReducer(initialState, {
  [salariesRequested.type]: (state) => {
    state.loading = true
    state.salariesSuccess = false
  },
  [salariesReceived.type]: (state, action) => {
    state.loading = false
    state.salariesSuccess = true
    state.salaries = action.payload.data
  },
  [salariesFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },

  // pay employees salaries
  [salariesPayRequested.type]: (state) => {
    state.salariesPayLoading = true
    state.salariesPaySuccess = false
  },
  [salariesPayReceived.type]: (state) => {
    state.salariesPayLoading = false
    state.salariesPaySuccess = true
  },
  [salariesPayFailed.type]: (state, action) => {
    state.salariesPayLoading = false
    toast.error(action.payload.message)
  }
})

export default employeesReducer

// employees salaries
export const loadEmployeesSalaries = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `games/${id}/employees`,
      onStart: salariesRequested.type,
      onSuccess: salariesReceived.type,
      onError: salariesFailed.type
    })
  )
}

// pay salaries

export const payEmployeesSalaries = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `games/${id}/employees/pay_salaries`,
      method: 'post',
      onStart: salariesPayRequested.type,
      onSuccess: salariesPayReceived.type,
      onError: salariesPayFailed.type
    })
  )
}
