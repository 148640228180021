import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'
import { toSnakeCase } from 'utils/transformText'
import verificationMarketing from 'utils/verificationMarketBoard'

// ACTIONS
const marketingBoardRequested = createAction('marketingBoard/requested')
const marketingBoardRequestFailed = createAction('marketingBoard/requestFailed')
const marketingBoardReceived = createAction('marketingBoard/received')

const marketingBoardSelectMarket = createAction('marketingBoard/selectMarket')
const marketingBoardSelectProductPoints = createAction('marketingBoard/selectProductPoints')

const marketingBoardPayCostsRequested = createAction('marketingBoard/payCostsRequested')
const marketingBoardPayCostsReceived = createAction('marketingBoard/payCostsReceived')
const marketingBoardPayCostsRequestFailed = createAction('marketingBoard/payCostsFailed')

const showAlertMarketingBoard = createAction('marketingBoard/showAlert')

const descriptionToAlertReceived = createAction('marketingBoard/descriptionToAlert')

// REDUCER
export const initialState = {
  data: [],
  loading: false,
  payCostsLoading: false,
  success: false,
  payCostsSuccess: false,
  selectedMarket: null,
  showAlertMarketingBoard: false,
  descriptionToAlert: ''
}

const marketingBoardListReducer = createReducer(initialState, {
  // Load marketing board
  [marketingBoardRequested.type]: (state) => {
    state.loading = true
  },
  [marketingBoardReceived.type]: (state, action) => {
    state.loading = false
    state.success = true
    state.data = action.payload.data
    state.selectedMarket = action.payload.data.length > 0 ? action.payload.data[0].market_id : null
  },
  [marketingBoardRequestFailed.type]: (state, action) => {
    state.loading = false
    state.success = false
    state.showAlertMarketingBoard = false
    toast.error(action.payload.message)
  },
  [marketingBoardSelectMarket.type]: (state, action) => {
    state.selectedMarket = action.payload
  },
  [marketingBoardSelectProductPoints.type]: (state, action) => {
    const { selectedMarket } = state

    // Update marketing board status
    const marketIndex = state.data.findIndex((item) => item.market_id === selectedMarket)
    const market = state.data[marketIndex]
    const marketProductName = `${toSnakeCase(action.payload.productType)}_communication_points`

    // Dynamically update marketing power
    const pointDifference = action.payload.points - market[marketProductName]
    market.marketing_power[action.payload.productType] +=
      pointDifference === 0 ? -action.payload.points : pointDifference
    market[marketProductName] = pointDifference === 0 ? 0 : action.payload.points
    state.data = [...state.data.slice(0, marketIndex), market, ...state.data.slice(marketIndex + 1)]
  },
  // Pay costs
  [marketingBoardPayCostsRequested.type]: (state) => {
    state.payCostsLoading = true
    state.payCostsSuccess = false
  },
  [marketingBoardPayCostsReceived.type]: (state, action) => {
    state.payCostsLoading = false
    state.payCostsSuccess = true
    state.data = action.payload.data
    state.selectedMarket = action.payload.data.length > 0 ? action.payload.data[0].market_id : null
  },
  [marketingBoardPayCostsRequestFailed.type]: (state, action) => {
    state.payCostsLoading = false
    state.payCostsSuccess = false
    toast.error(action.payload.message)
  },
  [showAlertMarketingBoard.type]: (state, action) => {
    state.showAlertMarketingBoard = action.payload
  },
  [descriptionToAlertReceived.type]: (state, action) => {
    state.descriptionToAlert = action.payload
  }
})

export default marketingBoardListReducer

// PUBLIC ACTIONS
export const loadMarketingBoard = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/marketing_boards`,
      onStart: marketingBoardRequested.type,
      onSuccess: marketingBoardReceived.type,
      onError: marketingBoardRequestFailed.type
    })
  )
}

export const selectMarketingBoard = (id) => (dispatch) => dispatch(marketingBoardSelectMarket(id))

export const selectProductPoints = (productType, points) => (dispatch) => {
  dispatch(marketingBoardSelectProductPoints({ productType, points }))
}

export const payCosts = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  const { data } = currentState.marketingBoard

  const marketingBoards = data.map((market) => ({
    marketing_board_id: market.id,
    products: {
      h_basic_communication_points: market.h_basic_communication_points,
      h_plus_communication_points: market.h_plus_communication_points,
      h_ultra_communication_points: market.h_ultra_communication_points,
      next_gen_communication_points: market.next_gen_communication_points
    }
  }))

  dispatch(
    apiCallBegan({
      url: `/games/${id}/marketing_boards/pay_costs`,
      method: 'POST',
      data: { points_to_assign: marketingBoards },
      onStart: marketingBoardPayCostsRequested.type,
      onSuccess: marketingBoardPayCostsReceived.type,
      onError: marketingBoardPayCostsRequestFailed.type
    })
  )
}

export const setShowAlertMarketingBoard = (value) => (dispatch) =>
  dispatch(showAlertMarketingBoard(value))

export const setDescriptionToAlert = (value) => (dispatch) =>
  dispatch(descriptionToAlertReceived(value))

export const checkMarkets = () => (dispatch, getState) => {
  const currentState = getState()
  const { data } = currentState.marketingBoard
  const { items } = currentState.marketStrategy

  const { showAlert, description } = verificationMarketing(data, items)

  if (showAlert) {
    dispatch(setShowAlertMarketingBoard(true))
    dispatch(setDescriptionToAlert(description))
  } else {
    dispatch(payCosts())
  }
}
