/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-expressions */
import { createAction, createReducer } from '@reduxjs/toolkit'

// Actions
const actionChanged = createAction('investments/actionChanged')
const selectedPlant = createAction('investments/selectedPlant')
const selectedProductType = createAction('investments/selectedProductType')
const selectedMachineType = createAction('investments/selectedMachineType')
const selectedMachine = createAction('investments/selectedMachine')
const investmentsCleaned = createAction('investments/cleaned')
const enableInvestmentAlert = createAction('investments/enableAlert')
const disableInvestmentAlert = createAction('investments/disableAlert')

// Reducer
export const initialState = {
  currentAction: null,
  showAlert: false,
  selectedPlant: null,
  selectedMachineType: null,
  selectedProductType: null,
  selectedMachine: null,
  alert: null
}

const investmentsReducer = createReducer(initialState, {
  [actionChanged.type]: (state, action) => {
    state.currentAction = action.payload
    state.selectedProduct = null
    state.selectedPlant = null
    state.selectedMachineType = null
    state.selectedMachine = null
  },
  [selectedPlant.type]: (state, action) => {
    state.selectedPlant = action.payload
  },
  [selectedProductType.type]: (state, action) => {
    state.selectedProductType = action.payload
  },
  [selectedMachineType.type]: (state, action) => {
    state.selectedMachineType = action.payload
  },
  [selectedMachine.type]: (state, action) => {
    state.selectedMachine = action.payload
  },
  [enableInvestmentAlert.type]: (state, action) => {
    state.alert = {
      description: action.payload.description,
      title: action.payload.title
    }
  },
  [disableInvestmentAlert.type]: (state) => {
    state.alert = null
  },
  [investmentsCleaned.type]: () => initialState
})
export default investmentsReducer

// Public actions
export const selectAction = (action) => (dispatch) => dispatch(actionChanged(action))

export const selectPlant = (plantId) => (dispatch) => dispatch(selectedPlant(plantId))

export const selectProductType = (productType) => (dispatch) =>
  dispatch(selectedProductType(productType))

export const selectMachineType = (machineType) => (dispatch) =>
  dispatch(selectedMachineType(machineType))

export const selectMachine = (machineId) => (dispatch) => dispatch(selectedMachine(machineId))

export const enableAlert = (info) => (dispatch) => dispatch(enableInvestmentAlert(info))

export const disableAlert = () => (dispatch) => dispatch(disableInvestmentAlert())

export const cleanInvestments = () => (dispatch) => dispatch(investmentsCleaned())
