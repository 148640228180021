export const MACHINE_STATES = {
  installing: 'installing',
  installed: 'installed',
  producing: 'producing',
  sold: 'sold'
}

export const MACHINE_TYPES = {
  manual_machine: 'manual',
  full_semi_automatic_machine: 'full-semi-automatic',
  full_automatic_machine: 'full-automatic'
}

export const MACHINE_QUARTERS = {
  manual_machine: 3,
  full_semi_automatic_machine: 2,
  full_automatic_machine: 1
}
