import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// index
const cashFlowRequested = createAction('cashFlow/requested')
const cashFlowReceived = createAction('cashFlow/received')
const cashFlowFailed = createAction('cashFlow/failed')

// REDUCER
export const initialState = {
  items: [],
  loading: false,
  success: false
}

const cashFlowReducer = createReducer(initialState, {
  // index
  [cashFlowRequested.type]: (state) => {
    state.loading = true
    state.success = false
  },
  [cashFlowReceived.type]: (state, action) => {
    state.loading = false
    state.success = true
    state.items = action.payload.data
  },
  [cashFlowFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  }
})

export default cashFlowReducer

// public
export const loadCashFlow = () => (dispatch, getState) => {
  const cashFlowState = getState()
  const id = gameIdSelector(cashFlowState)
  dispatch(
    apiCallBegan({
      url: `games/${id}/cash_flow_statement/`,
      onStart: cashFlowRequested.type,
      onSuccess: cashFlowReceived.type,
      onError: cashFlowFailed.type
    })
  )
}
