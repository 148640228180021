import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// ACTIONS
// LoadRawMaterialsInventory
const rawMaterialsInventoryRequested = createAction('rawMaterialsInventory/requested')
const rawMaterialsInventoryRequestFailed = createAction('rawMaterialsInventory/requestFailed')
const rawMaterialsInventoryReceived = createAction('rawMaterialsInventory/received')

// ReceiveRawMaterials
const rawMaterialsReceiveOrdersRequested = createAction('rawMaterialsReceiveOrders/requested')
const rawMaterialsReceiveOrdersReceived = createAction('rawMaterialsReceiveOrders/requestFailed')
const rawMaterialsReceiveOrdersRequestFailed = createAction('rawMaterialsReceiveOrders/received')

// REDUCER
export const initialState = {
  data: [],
  loading: false,
  receiveOrdersLoading: false,
  success: false,
  receiveOrdersSuccess: false
}

const rawMaterialsInventoryReducer = createReducer(initialState, {
  [rawMaterialsInventoryRequested.type]: (state) => {
    state.loading = true
    state.success = false
  },
  [rawMaterialsInventoryReceived.type]: (state, action) => {
    state.loading = false
    state.success = true
    state.data = action.payload.data
  },
  [rawMaterialsInventoryRequestFailed.type]: (state, action) => {
    state.loading = false
    state.success = false
    toast.error(action.payload.message)
  },
  [rawMaterialsReceiveOrdersRequested.type]: (state) => {
    state.receiveOrdersLoading = true
    state.receiveOrdersSuccess = false
  },
  [rawMaterialsReceiveOrdersReceived.type]: (state, action) => {
    state.receiveOrdersLoading = false
    state.receiveOrdersSuccess = true
    state.data = action.payload.data
  },
  [rawMaterialsReceiveOrdersRequestFailed.type]: (state, action) => {
    state.receiveOrdersLoading = false
    state.receiveOrdersSuccess = false
    toast.error(action.payload.message)
  }
})

export default rawMaterialsInventoryReducer

// PUBLIC ACTIONS
export const loadRawMaterialsInventory = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/raw_materials`,
      onStart: rawMaterialsInventoryRequested.type,
      onSuccess: rawMaterialsInventoryReceived.type,
      onError: rawMaterialsInventoryRequestFailed.type
    })
  )
}

export const receiveRawMaterials = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/raw_materials/receive_orders`,
      method: 'post',
      onStart: rawMaterialsReceiveOrdersRequested.type,
      onSuccess: rawMaterialsReceiveOrdersReceived.type,
      onError: rawMaterialsReceiveOrdersRequestFailed.type
    })
  )
}
