import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'

// ACTIONS
const userInfoRequested = createAction('userInfo/requested')
const userInfoRequestFailed = createAction('userInfo/requestFailed')
const userInfoReceived = createAction('userInfo/received')
const userInfoClean = createAction('userInfo/clean')

const changeTeamRequested = createAction('changeTeam/requested')
const changeTeamRequestFailed = createAction('changeTeam/requestFailed')
const changeTeamReceived = createAction('changeTeam/received')

const changeTeamInGame = createAction('changeTeam/inGame')

// REDUCER
export const initialState = {
  data: null,
  loading: false,
  inGameTeamChanged: false,
  changeTeamSuccess: false
}

const userInfoReducer = createReducer(initialState, {
  // User info
  [userInfoRequested.type]: (state) => {
    state.loading = true
  },
  [userInfoReceived.type]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [userInfoRequestFailed.type]: (state) => {
    state.loading = false
  },
  // Change team
  [changeTeamRequested.type]: (state) => {
    state.loading = true
    state.changeTeamSuccess = false
  },
  [changeTeamReceived.type]: (state) => {
    state.loading = false
    state.changeTeamSuccess = true
  },
  [changeTeamRequestFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  [changeTeamInGame.type]: (state) => {
    state.inGameTeamChanged = true
  },
  [userInfoClean.type]: () => initialState
})

export default userInfoReducer

// Public actions
export const loadUserInfo = () =>
  apiCallBegan({
    url: '/user',
    onStart: userInfoRequested.type,
    onSuccess: userInfoReceived.type,
    onError: userInfoRequestFailed.type
  })

export const updateCurrentTeam = (teamId) =>
  apiCallBegan({
    url: '/user/update_current_team',
    method: 'put',
    data: { team_id: teamId },
    onStart: changeTeamRequested.type,
    onSuccess: changeTeamReceived.type,
    onError: changeTeamRequestFailed.type
  })

export const cleanUserInfo = () => (dispatch) => dispatch(userInfoClean())

export const updateTeamInGame = () => (dispatch) => dispatch(changeTeamInGame())
