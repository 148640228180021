import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// ACTIONS
const kpiAnalysisRequested = createAction('kpiAnalysis/requested')
const kpiAnalysisReceived = createAction('kpiAnalysis/received')
const kpiAnalysisFailed = createAction('kpiAnalysis/requestFailed')

const contributionMarginRequested = createAction('contributionMargin/requested')
const contributionMarginReceived = createAction('contributionMargin/received')
const contributionMarginFailed = createAction('contributionMargin/requestFailed')

const costAccountingRequested = createAction('contributionMargin/requested')
const costAccountingReceived = createAction('costAccounting/received')
const costAccountingFailed = createAction('costAccounting/requestFailed')

const calculationsClean = createAction('calculations/clean')

// REDUCER
export const initialState = {
  data: {
    kpiAnalysis: [],
    contributionMargin: [],
    costAccounting: []
  },
  loading: false,
  requestCalculationsSuccess: false
}

const calculationsReducer = createReducer(initialState, {
  [kpiAnalysisRequested.type]: (state) => {
    state.loading = true
    state.requestCalculationsSuccess = false
  },
  [kpiAnalysisReceived.type]: (state, action) => {
    state.loading = false
    state.data.kpiAnalysis = action.payload.data
    state.requestCalculationsSuccess = true
  },
  [kpiAnalysisFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  [contributionMarginRequested.type]: (state) => {
    state.loading = true
    state.requestCalculationsSuccess = false
  },
  [contributionMarginReceived.type]: (state, action) => {
    state.loading = false
    state.data.contributionMargin = action.payload.data
    state.requestCalculationsSuccess = true
  },
  [contributionMarginFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  [costAccountingRequested.type]: (state) => {
    state.loading = true
    state.requestCalculationsSuccess = false
  },
  [costAccountingReceived.type]: (state, action) => {
    state.loading = false
    state.data.costAccounting = action.payload.data
    state.requestCalculationsSuccess = true
  },
  [costAccountingFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  [calculationsClean.type]: () => initialState
})

export default calculationsReducer

// Public actions
export const loadKpiAnalysis = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `/games/${id}/calculations/kpi_analysis`,
      onStart: kpiAnalysisRequested.type,
      onSuccess: kpiAnalysisReceived.type,
      onError: kpiAnalysisFailed.type
    })
  )
}

export const loadContributionMargin = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `/games/${id}/calculations/contribution_margin`,
      onStart: contributionMarginRequested.type,
      onSuccess: contributionMarginReceived.type,
      onError: contributionMarginFailed.type
    })
  )
}

export const loadCostAccounting = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `/games/${id}/calculations/cost_accounting`,
      onStart: costAccountingRequested.type,
      onSuccess: costAccountingReceived.type,
      onError: costAccountingFailed.type
    })
  )
}

export const cleanCalculations = () => (dispatch) => dispatch(calculationsClean())
