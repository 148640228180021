import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// ACTIONS
const trainingIndexRequested = createAction('trainingIndex/requested')
const trainingIndexRequestFailed = createAction('trainingIndex/requestFailed')
const trainingIndexReceived = createAction('trainingIndex/received')

const addPoints = createAction('trainingIndex/addPoins')
const removePoints = createAction('trainingIndex/removePoins')

const trainingIndexDeployRequested = createAction('trainingIndex/deployRequested')
const trainingIndexDeployRequestFailed = createAction('trainingIndex/deployRequestFailed')
const trainingIndexDeployReceived = createAction('trainingIndex/deployReceived')

const trainingIndexCleaned = createAction('trainingIndex/cleaned')

// REDUCER
export const initialState = {
  data: null,
  loading: false,
  deployLoading: false,
  success: false,
  deploySuccess: false,
  training_points: 0
}

const trainingIndexListReducer = createReducer(initialState, {
  [trainingIndexRequested.type]: (state) => {
    state.loading = true
  },
  [trainingIndexReceived.type]: (state, action) => {
    state.loading = false
    state.success = true
    state.data = action.payload.data
  },
  [trainingIndexRequestFailed.type]: (state, action) => {
    state.loading = false
    state.success = false
    toast.error(action.payload.message)
  },
  [addPoints.type]: (state) => {
    state.training_points += 1
    state.data.current_index += 1
  },
  [removePoints.type]: (state) => {
    state.training_points -= 1
    state.data.current_index -= 1
  },
  // Deploy training points
  [trainingIndexDeployRequested.type]: (state) => {
    state.deployLoading = true
    state.deploySuccess = false
  },
  [trainingIndexDeployReceived.type]: (state, action) => {
    state.deployLoading = false
    state.deploySuccess = true
    state.data = action.payload.data
    state.training_points = 0
  },
  [trainingIndexDeployRequestFailed.type]: (state, action) => {
    state.deployLoading = false
    state.deploySuccess = false
    toast.error(action.payload.message)
  },
  [trainingIndexCleaned.type]: () => initialState
})

export default trainingIndexListReducer

// PUBLIC ACTIONS
export const loadTrainingIndex = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/training`,
      onStart: trainingIndexRequested.type,
      onSuccess: trainingIndexReceived.type,
      onError: trainingIndexRequestFailed.type
    })
  )
}

export const addPointsCurrentIndex = () => (dispatch) => {
  dispatch(addPoints())
}

export const removePointsCurrentIndex = () => (dispatch) => {
  dispatch(removePoints())
}

export const deployTrainingPoints = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())
  const { training_points } = getState().trainingIndex

  dispatch(
    apiCallBegan({
      url: `/games/${id}/training/train`,
      method: 'post',
      data: { training_points },
      onStart: trainingIndexDeployRequested.type,
      onSuccess: trainingIndexDeployReceived.type,
      onError: trainingIndexDeployRequestFailed.type
    })
  )
}

export const cleanTrainingIndex = () => (dispatch) => dispatch(trainingIndexCleaned())
