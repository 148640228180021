import React, { memo } from 'react'
import PropTypes from 'prop-types'

import CloseIcon from 'assets/icons/close.png'

function CloseButton({ className, handleClose }) {
  return (
    <button className={`btn close-button ${className}`} onClick={handleClose} type="button">
      <img src={CloseIcon} alt="close" className="close-button__icon" />
    </button>
  )
}

CloseButton.propTypes = {
  className: PropTypes.string,
  handleClose: PropTypes.func.isRequired
}

CloseButton.defaultProps = {
  className: ''
}

export default memo(CloseButton)
