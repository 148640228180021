import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// ACTIONS
const productQualityStrategyRequested = createAction('productQualityStrategy/requested')
const productQualityStrategyRequestFailed = createAction('productQualityStrategy/requestFailed')
const productQualityStrategyReceived = createAction('productQualityStrategy/received')

const reloadProductQualityStrategyRequested = createAction('reloadProductQualityStrategy/requested')
const reloadProductQualityStrategyRequestFailed = createAction(
  'reloadProductQualityStrategy/requestFailed'
)
const reloadProductQualityStrategyReceived = createAction('reloadProductQualityStrategy/received')

const assignEmployees = createAction('productQualityStrategy/assignEmployees')
const removeEmployees = createAction('productQualityStrategy/removeEmployees')

const addPoints = createAction('productQualityStrategy/addPoins')
const removePoints = createAction('productQualityStrategy/removePoins')

const productQualityStrategyDeployRequested = createAction('productQualityStrategy/deployRequested')
const productQualityStrategyDeployReceived = createAction('productQualityStrategy/deployReceived')
const productQualityStrategyDeployRequestFailed = createAction(
  'productQualityStrategy/deployRequestFailed'
)

// REDUCER
export const initialState = {
  current_index: null,
  originalCurrentIndex: null,
  quality_label: null,
  loading: false,
  deployLoading: false,
  success: false,
  deploySuccess: false,
  deployedEmployees: 0,
  availableEmployees: 0,
  employeesAmount: 0,
  reloadLoading: false,
  reloadSuccess: false
}

const productQualityStrategyListReducer = createReducer(initialState, {
  // Load
  [productQualityStrategyRequested.type]: (state) => {
    state.loading = true
  },
  [productQualityStrategyReceived.type]: (state, action) => {
    state.loading = false
    state.success = true
    state.current_index = action.payload.data.current_index
    state.originalCurrentIndex = action.payload.data.current_index
    state.quality_label = action.payload.data.quality_label
    state.availableEmployees = action.payload.data.rd_employees_count
    state.deployedEmployees = action.payload.data.deployed_rd_employees
    state.employeesAmount = action.payload.data.deployed_rd_employees
  },
  [productQualityStrategyRequestFailed.type]: (state, action) => {
    state.loading = false
    state.success = false
    toast.error(action.payload.message)
  },
  // Reload
  [reloadProductQualityStrategyRequested.type]: (state) => {
    state.reloadLoading = true
  },
  [reloadProductQualityStrategyReceived.type]: (state, action) => {
    state.reloadLoading = false
    state.reloadSuccess = true
    state.availableEmployees =
      action.payload.data.rd_employees_count - (state.employeesAmount - state.deployedEmployees)
  },
  [reloadProductQualityStrategyRequestFailed.type]: (state, action) => {
    state.reloadLoading = false
    state.reloadSuccess = false
    toast.error(action.payload.message)
  },
  [assignEmployees.type]: (state) => {
    state.employeesAmount += 1
    state.availableEmployees -= 1
  },
  [removeEmployees.type]: (state) => {
    state.employeesAmount -= 1
    state.availableEmployees += 1
  },
  [addPoints.type]: (state, action) => {
    const newPoints = state.originalCurrentIndex + action.payload

    if (newPoints > 50 && newPoints < 100) state.current_index = newPoints

    if (newPoints >= 100) state.current_index = 100

    if (newPoints <= 50) state.current_index = 50
  },
  [removePoints.type]: (state, action) => {
    const points = state.current_index - action.payload

    state.current_index = points < 50 ? 50 : points
  },
  // Deploy rd employees
  [productQualityStrategyDeployRequested.type]: (state) => {
    state.deployLoading = true
    state.deploySuccess = false
  },
  [productQualityStrategyDeployReceived.type]: (state, action) => {
    state.deployLoading = false
    state.deploySuccess = true
    state.current_index = action.payload.data.current_index
    state.quality_label = action.payload.data.quality_label
    state.employeesAmount = 0
  },
  [productQualityStrategyDeployRequestFailed.type]: (state, action) => {
    state.deployLoading = false
    state.deploySuccess = false
    toast.error(action.payload.message)
  }
})

export default productQualityStrategyListReducer

// PUBLIC ACTIONS
export const loadProductQualityStrategy = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/product_quality`,
      onStart: productQualityStrategyRequested.type,
      onSuccess: productQualityStrategyReceived.type,
      onError: productQualityStrategyRequestFailed.type
    })
  )
}

export const assignProductQualityEmployees = () => (dispatch) => {
  dispatch(assignEmployees())
}

export const removeProductQualityEmployees = () => (dispatch) => {
  dispatch(removeEmployees())
}

export const addPointsCurrentIndex = (points) => (dispatch) => {
  dispatch(addPoints(points))
}

export const removePointsCurrentIndex = (points) => (dispatch) => {
  dispatch(removePoints(points))
}

export const deployRdEmployees = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())
  const { employeesAmount } = getState().productQualityStrategy

  dispatch(
    apiCallBegan({
      url: `/games/${id}/product_quality/deploy`,
      method: 'post',
      data: { employees_amount: employeesAmount },
      onStart: productQualityStrategyDeployRequested.type,
      onSuccess: productQualityStrategyDeployReceived.type,
      onError: productQualityStrategyDeployRequestFailed.type
    })
  )
}

export const reloadProductQualityEmployees = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/product_quality`,
      onStart: reloadProductQualityStrategyRequested.type,
      onSuccess: reloadProductQualityStrategyReceived.type,
      onError: reloadProductQualityStrategyRequestFailed.type
    })
  )
}
