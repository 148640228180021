import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'
import { PRODUCT_TYPES } from 'constants/productTypes'

// ACTIONS
const procurementRequested = createAction('procurement/requested')
const procurementRequestFailed = createAction('procurement/requestFailed')
const procurementReceived = createAction('procurement/received')

const procurementBuyRequested = createAction('procurement/buyRequested')
const procurementBuyRequestFailed = createAction('procurement/buyRequestFailed')
const procurementBuyReceived = createAction('procurement/buyReceived')

const procurementUpdateRequestedMaterial = createAction('procurement/updateRequestedMaterial')

// REDUCER
export const initialState = {
  buy: {
    [PRODUCT_TYPES[0]]: 0,
    [PRODUCT_TYPES[1]]: 0,
    [PRODUCT_TYPES[2]]: 0,
    [PRODUCT_TYPES[3]]: 0
  },
  loading: false,
  buyLoading: false,
  success: false,
  buySuccess: false,
  loadingRequested: false,
  successRequested: false
}

const procurementReducer = createReducer(initialState, {
  [procurementRequested.type]: (state) => {
    state.loadingRequested = true
    state.successRequested = false
  },
  [procurementReceived.type]: (state, action) => {
    state.loadingRequested = false
    state.successRequested = true
    state.buy = action.payload.data
  },
  [procurementRequestFailed.type]: (state, action) => {
    state.loadingRequested = false
    state.successRequested = false
    toast.error(action.payload.message)
  },
  // Buy raw materials
  [procurementBuyRequested.type]: (state) => {
    state.buyLoading = true
    state.buySuccess = false
  },
  [procurementBuyReceived.type]: (state) => {
    state.buyLoading = false
    state.buySuccess = true
  },
  [procurementBuyRequestFailed.type]: (state, action) => {
    state.buyLoading = false
    state.buySuccess = false
    toast.error(action.payload.message)
  },
  [procurementUpdateRequestedMaterial.type]: (state, action) => {
    state.buy[action.payload.productType] = action.payload.quantity
  }
})

export default procurementReducer

// PUBLIC ACTIONS
export const updateRequestedMaterial = (productType, quantity) => (dispatch) => {
  dispatch(procurementUpdateRequestedMaterial({ productType, quantity }))
}

export const loadRequestedRawMaterials = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/raw_materials/requested`,
      method: 'get',
      onStart: procurementRequested.type,
      onSuccess: procurementReceived.type,
      onError: procurementRequestFailed.type
    })
  )
}

export const buyRawMaterials = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  const { buy } = currentState.procurement

  const data = {
    h_basic_quantity: buy[PRODUCT_TYPES[0]],
    h_plus_quantity: buy[PRODUCT_TYPES[1]],
    h_ultra_quantity: buy[PRODUCT_TYPES[2]],
    next_gen_quantity: buy[PRODUCT_TYPES[3]]
  }

  dispatch(
    apiCallBegan({
      url: `/games/${id}/raw_materials/buy`,
      method: 'post',
      data,
      onStart: procurementBuyRequested.type,
      onSuccess: procurementBuyReceived.type,
      onError: procurementBuyRequestFailed.type
    })
  )
}
