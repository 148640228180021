import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from './apiCalls'
import i18n from '../i18n'

// ACTIONS
const loggedIn = createAction('auth/loggedIn')
const loggedOut = createAction('auth/loggedOut')
const signedUp = createAction('auth/signedUp')

const logInError = createAction('auth/loginError')
const signUpError = createAction('auth/signedUpError')

const initialState = {
  loggedIn: localStorage.getItem('smb_access_token'),
  userType: null
}

// REDUCER
const authReducer = createReducer(initialState, {
  [loggedIn.type]: (state, action) => {
    state.loggedIn = true
    state.userType = action.payload.user_type
    localStorage.setItem('smb_access_token', action.payload.access_token)
    localStorage.setItem('smb_refresh_token', action.payload.refresh_token)
    toast.success(i18n.t('login.success'), { autoClose: 3000 })
  },
  [loggedOut.type]: (state) => {
    state.loggedIn = false
    state.userType = null
    localStorage.removeItem('smb_access_token')
    localStorage.removeItem('smb_refresh_token')
    toast.success(i18n.t('logout.success'), { autoClose: 3000 })
  },
  [signedUp.type]: () => {
    toast.success(i18n.t('success.confirmation'), {
      autoClose: false
    })
  },
  [logInError.type]: (state, action) => {
    toast.error(action.payload.message)
  },
  [signUpError.type]: (state, action) => {
    toast.error(action.payload.message)
  }
})

export default authReducer

// PUBLIC ACTIONS
export const signInPlayer = (accessCode) =>
  apiCallBegan({
    url: '/users/sign_in',
    method: 'post',
    data: accessCode,
    onSuccess: loggedIn.type,
    onError: logInError.type
  })

export const signOut = () =>
  apiCallBegan({
    url: '/users/sign_out',
    method: 'delete',
    onSuccess: loggedOut.type,
    onError: loggedOut.type
  })
