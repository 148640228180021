import { createSelector } from '@reduxjs/toolkit'
import { USER_TYPES } from 'constants/users'

// Game selectors
export const gameIdSelector = createSelector(
  (state) => state.user.game.data,
  (game) => game?.id
)

export const gameAiSelector = createSelector(
  (state) => state.user.game.data,
  (game) => game?.ai
)

export const gameSettingsSelector = createSelector(
  (state) => state.user.game.data?.game_setting,
  (settings) => settings?.setup
)

export const gameTeamsSelector = createSelector(
  (state) => state.user.game.data,
  (game) => {
    const teams = [...game.teams]

    return teams.sort((a, b) => a.id - b.id)
  }
)

export const gameCurrentGraphic = createSelector(
  (state) => state.user.game.data,
  (game) => game.current_graphic
)

// Team selectors
export const currentActionSelector = createSelector(
  (state) => state.user.team.data,
  (team) => team.action_state
)

export const currentGeneralStateSelector = createSelector(
  (state) => state.user.team.data,
  (team) => team.general_state
)

export const currentGameStateSelector = createSelector(
  (state) => state.user.game.data,
  (game) => game.aasm_state
)

export const currentYearTeamSelector = createSelector(
  (state) => state.user.team.data,
  (team) => team.current_year
)

export const yearZeroTeamSelector = createSelector(
  (state) => state.user.team.data,
  (team) => team.current_year === 0
)

// Bank selectors
export const cashSelector = createSelector(
  (state) => state.user.bank.data,
  (bank) => bank.cash
)

export const accountReceivablesSelector = createSelector(
  (state) => state.user.bank.data,
  (bank) => bank.account_receivables
)

// User selectors
export const userTypeSelector = createSelector(
  (state) => state.user.info.data,
  (user) => user.user_type
)

export const isFacilitatorSelector = createSelector(
  (state) => state.user.info.data,
  (user) => user?.user_type === USER_TYPES.facilitator
)

export const isPlayerSelector = createSelector(
  (state) => state.user.info.data,
  (user) => user?.user_type === USER_TYPES.player
)

export const isCurrentPlayerSelector = createSelector(
  (state) => state.user.info.data,
  (state) => state.user.team.data.current_player,
  (player, currentPlayer) => player.id === currentPlayer.id
)
