import { combineReducers } from 'redux'
import bank from './bank'
import game from './game'
import info from './info'
import team from './team'
// import password from './password'
// import update from './update'

export default combineReducers({
  bank,
  game,
  info,
  team
})
