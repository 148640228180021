import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

export default function ErrorBoundaryProvider({ children }) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production']
  })

  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

  return <ErrorBoundary>{children}</ErrorBoundary>
}

export function addBugsnagMetadata(user) {
  const teamData = user.team && {
    team_id: user.team.id,
    team_name: user.team.name,
    team_action_state: user.team.action_state,
    team_general_state: user.team.general_state,
    team_current_year: user.team.current_year,
    team_current_player: user.team.current_player_id
  }

  const gameData = user.game && {
    game_id: user.game.id,
    game_name: user.game.name
  }

  Bugsnag.addMetadata('current_user_data', {
    user_id: user.id,
    user_alias: user.alias,
    user_type: user.user_type,
    ...teamData,
    ...gameData
  })
}

export function clearBugsnagMetadata() {
  Bugsnag.clearMetadata('current_user_data')
}
