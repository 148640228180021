import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './locales/en.json'
import tr from './locales/tr.json'
import cn from './locales/cn.json'

const options = {
  interpolation: {
    escapeValue: false
  },

  debug: false,

  resources: {
    en: {
      common: en.en
    },
    tr: {
      common: tr.tr
    },
    cn: {
      common: cn.cn
    }
  },

  fallbackLng: 'en',

  ns: ['common'],

  defaultNS: 'common',

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  }
}

i18n.use(LanguageDetector).init(options)

export default i18n
