import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// ACTIONS
const salesStrategyRequested = createAction('salesStrategy/requested')
const salesStrategyRequestFailed = createAction('salesStrategy/requestFailed')
const salesStrategyReceived = createAction('salesStrategy/received')

const assignEmployees = createAction('salesStrategy/assignEmployees')
const removeEmployees = createAction('salesStrategy/removeEmployees')

const salesStrategyDeployRequested = createAction('salesStrategy/deployRequested')
const salesStrategyDeployRequestFailed = createAction('salesStrategy/deployRequestFailed')
const salesStrategyDeployReceived = createAction('salesStrategy/deployReceived')

const salesStrategyPayCostsRequested = createAction('salesStrategy/payCostsRequested')
const salesStrategyPayCostsRequestFailed = createAction('salesStrategy/payCostsRequestFailed')
const salesStrategyPayCostsReceived = createAction('salesStrategy/payCostsReceived')

const initAssignment = createAction('salesStrategy/initAssignment')

const reloadSalesStrategyRequested = createAction('reloadSalesStrategy/requested')
const reloadSalesStrategyRequestFailed = createAction('reloadSalesStrategy/requestFailed')
const reloadSalesStrategyReceived = createAction('reloadSalesStrategy/received')

// REDUCER
export const initialState = {
  items: [],
  loading: false,
  reloadLoading: false,
  deployloading: false,
  payCostsLoading: false,
  success: false,
  reloadSuccess: false,
  deploySuccess: false,
  payCostsSuccess: false,
  availableEmployees: 0,
  employeesAssignment: []
}

const salesStrategyListReducer = createReducer(initialState, {
  [salesStrategyRequested.type]: (state) => {
    state.loading = true
    state.success = false
  },
  [salesStrategyReceived.type]: (state, action) => {
    const filterdMarkets = action.payload.data.markets.filter((market) => market.can_deploy)
    state.loading = false
    state.success = true
    state.items = action.payload.data.markets
    state.availableEmployees = action.payload.data.sales_employees_count
    state.employeesAssignment = filterdMarkets.map((market) => ({
      market_id: market.id,
      employees_amount: market.deployed_sales_employees
    }))
  },
  [salesStrategyRequestFailed.type]: (state, action) => {
    state.loading = false
    state.success = false
    toast.error(action.payload.message)
  },
  [assignEmployees.type]: (state, action) => {
    const index = state.employeesAssignment.findIndex(
      (market) => market.market_id === action.payload
    )

    // Market not found
    if (index === -1) {
      state.employeesAssignment = [
        ...state.employeesAssignment,
        { market_id: action.payload, employees_amount: 1 }
      ]
    } else {
      state.employeesAssignment = [
        ...state.employeesAssignment.slice(0, index),
        {
          ...state.employeesAssignment[index],
          employees_amount: state.employeesAssignment[index].employees_amount + 1
        },
        ...state.employeesAssignment.slice(index + 1)
      ]
    }

    state.availableEmployees -= 1
  },
  [removeEmployees.type]: (state, action) => {
    const index = state.employeesAssignment.findIndex(
      (market) => market.market_id === action.payload
    )

    state.employeesAssignment = [
      ...state.employeesAssignment.slice(0, index),
      {
        ...state.employeesAssignment[index],
        employees_amount: state.employeesAssignment[index].employees_amount - 1
      },
      ...state.employeesAssignment.slice(index + 1)
    ]

    state.availableEmployees += 1
  },
  // Deploy sales employees
  [salesStrategyDeployRequested.type]: (state) => {
    state.deployloading = true
    state.deploySuccess = false
  },
  [salesStrategyDeployReceived.type]: (state, action) => {
    state.deployloading = false
    state.deploySuccess = true
    state.items = action.payload.data.markets
    state.availableEmployees = action.payload.data.sales_employees_count
  },
  [salesStrategyDeployRequestFailed.type]: (state, action) => {
    state.deployloading = false
    state.deploySuccess = false
    toast.error(action.payload.message)
  },
  // Pay fixed sales costs
  [salesStrategyPayCostsRequested.type]: (state) => {
    state.payCostsLoading = true
    state.payCostsSuccess = false
  },
  [salesStrategyPayCostsReceived.type]: (state) => {
    state.payCostsLoading = false
    state.payCostsSuccess = true
  },
  [salesStrategyPayCostsRequestFailed.type]: (state, action) => {
    state.payCostsLoading = false
    state.payCostsSuccess = false
    toast.error(action.payload.message)
  },
  [initAssignment.type]: (state) => {
    const filterdMarkets = state.items.filter(
      (market) => market.can_deploy || market.deployed_sales_employees > 0
    )
    state.employeesAssignment = filterdMarkets.map((market) => ({
      market_id: market.id,
      employees_amount: market.deployed_sales_employees
    }))
    state.success = false
  },
  [reloadSalesStrategyRequested.type]: (state) => {
    state.reloadLoading = true
  },
  [reloadSalesStrategyReceived.type]: (state, action) => {
    state.reloadLoading = false
    state.reloadSuccess = true
    state.availableEmployees =
      action.payload.data.sales_employees_count -
      (state.employeesAssignment.reduce((acc, market) => acc + market.employees_amount, 0) -
        action.payload.data.markets.reduce(
          (acc, market) => acc + market.deployed_sales_employees,
          0
        ))
  },
  [reloadSalesStrategyRequestFailed.type]: (state, action) => {
    state.reloadLoading = false
    state.reloadSuccess = false
    toast.error(action.payload.message)
  }
})

export default salesStrategyListReducer

// PUBLIC ACTIONS
export const loadSalesStrategy = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/sales_organization`,
      onStart: salesStrategyRequested.type,
      onSuccess: salesStrategyReceived.type,
      onError: salesStrategyRequestFailed.type
    })
  )
}

export const assignSalesEmployees = (market) => (dispatch) => {
  dispatch(assignEmployees(market))
}

export const removeSalesEmployees = (market) => (dispatch) => {
  dispatch(removeEmployees(market))
}

export const deploySalesEmployees = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())
  const { employeesAssignment } = getState().salesStrategy

  dispatch(
    apiCallBegan({
      url: `/games/${id}/sales_organization/deploy`,
      method: 'post',
      data: {
        employees_assignment: employeesAssignment
      },
      onStart: salesStrategyDeployRequested.type,
      onSuccess: salesStrategyDeployReceived.type,
      onError: salesStrategyDeployRequestFailed.type
    })
  )
}

export const payFixedSalesCosts = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/sales_organization/pay_fixed_sales_costs`,
      method: 'post',
      onStart: salesStrategyPayCostsRequested.type,
      onSuccess: salesStrategyPayCostsReceived.type,
      onError: salesStrategyPayCostsRequestFailed.type
    })
  )
}

export const initializeAssignment = () => (dispatch) => dispatch(initAssignment())

export const reloadSalesStrategyEmployees = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/sales_organization`,
      onStart: reloadSalesStrategyRequested.type,
      onSuccess: reloadSalesStrategyReceived.type,
      onError: reloadSalesStrategyRequestFailed.type
    })
  )
}
