import { createAction, createReducer } from '@reduxjs/toolkit'
import i18n from 'i18n'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// ACTIONS
const teamRequested = createAction('team/requested')
const teamRequestFailed = createAction('team/requestFailed')
const teamReceived = createAction('team/received')
const teamClean = createAction('team/clean')

const teamAddEmployee = createAction('team/addEmployee')
const teamRemoveEmployee = createAction('team/removeEmployee')

const teamHireReceived = createAction('team/hireReceived')

const teamPayCeoAndChangeQRequested = createAction('team/payCeoAndChangeQRequested')
const teamPayCeoAndChangeQReceived = createAction('team/payCeoAndChangeQReceived')
const teamPayCeoAndChangeQRequestFailed = createAction('team/payCeoAndChangeQRequestFailed')

const teamRetryAiActionRequested = createAction('team/retryAiActionRequested')
const teamRetryAiActionReceived = createAction('team/retryAiActionReceived')
const teamRetryAiActionRequestFailed = createAction('team/retryAiActionRequestFailed')

// REDUCER
export const initialState = {
  data: null,
  hireEmployees: {
    rd: 0,
    seller: 0
  },
  hireEmployeesSuccess: false,
  loading: false,
  payCeoAndChangeQLoading: false,
  payCeoAndChangeQSuccess: false,
  retryAiActionLoading: false,
  retryAiActionSuccess: false
}

const teamReducer = createReducer(initialState, {
  [teamRequested.type]: (state) => {
    state.loading = true
    state.hireEmployeesSuccess = false
    state.payCeoAndChangeQSuccess = false
  },
  [teamReceived.type]: (state, action) => {
    state.loading = false
    state.data = action.payload
    state.hireEmployeesSuccess = false
  },
  [teamRequestFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  [teamClean.type]: () => initialState,
  [teamAddEmployee.type]: (state, action) => {
    state.hireEmployees[action.payload] += 1
  },
  [teamRemoveEmployee.type]: (state, action) => {
    state.hireEmployees[action.payload] -= 1
  },
  [teamHireReceived.type]: (state) => {
    state.loading = false
    state.hireEmployees = {
      rd: 0,
      seller: 0
    }
    state.hireEmployeesSuccess = true
  },
  // Pay ceo and change q
  [teamPayCeoAndChangeQRequested.type]: (state) => {
    state.payCeoAndChangeQLoading = true
    state.payCeoAndChangeQSuccess = false
  },
  [teamPayCeoAndChangeQReceived.type]: (state) => {
    state.payCeoAndChangeQLoading = false
    state.payCeoAndChangeQSuccess = true
  },
  [teamRequestFailed.type]: (state, action) => {
    state.payCeoAndChangeQLoading = false
    state.payCeoAndChangeQSuccess = false
    toast.error(action.payload.message)
  },
  // Retry AI action
  [teamRetryAiActionRequested.type]: (state) => {
    state.retryAiActionLoading = true
    state.retryAiActionSuccess = false
  },
  [teamRetryAiActionReceived.type]: (state) => {
    state.retryAiActionLoading = false
    state.retryAiActionSuccess = true
    toast.success(i18n.t('success.confirmation'))
  },
  [teamRetryAiActionRequestFailed.type]: (state, action) => {
    state.retryAiActionLoading = false
    state.retryAiActionSuccess = false
    toast.error(action.payload.message)
  }
})

export default teamReducer

// Public actions
export const loadUserTeam = () =>
  apiCallBegan({
    url: `/user/team`,
    onStart: teamRequested.type,
    onSuccess: teamReceived.type,
    onError: teamRequestFailed.type
  })

export const cleanUserTeam = () => (dispatch) => dispatch(teamClean())

export const addEmployee = (employeeType) => (dispatch) => dispatch(teamAddEmployee(employeeType))

export const removeEmployee = (employeeType) => (dispatch) =>
  dispatch(teamRemoveEmployee(employeeType))

export const hire = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  const { hireEmployees } = currentState.user.team

  const employees = {
    sales_quantity: hireEmployees.seller,
    rd_quantity: hireEmployees.rd
  }

  dispatch(
    apiCallBegan({
      url: `games/${id}/employees/hire`,
      method: 'post',
      data: employees,
      onStart: teamRequested.type,
      onSuccess: teamHireReceived.type,
      onError: teamRequestFailed.type
    })
  )
}

export const payCeoAndChangeQ = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `games/${id}/turns/change`,
      method: 'post',
      onStart: teamPayCeoAndChangeQRequested.type,
      onSuccess: teamPayCeoAndChangeQReceived.type,
      onError: teamPayCeoAndChangeQRequestFailed.type
    })
  )
}

export const retryAiAction = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `games/${id}/ai/retry`,
      method: 'post',
      onStart: teamRetryAiActionRequested.type,
      onSuccess: teamRetryAiActionReceived.type,
      onError: teamRetryAiActionRequestFailed.type
    })
  )
}
