export const capitalizeFirstLetter = (string) => {
  const downcase = string.toLowerCase()

  return downcase.charAt(0).toUpperCase() + downcase.slice(1)
}

export const capitalizeFirstLetters = (string, separator = ' ') => {
  const words = string.split(separator)
  for (let i = 0; i < words.length; i += 1) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1)
  }
  return words.join(separator)
}

export const toSnakeCase = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('_')
