import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// ACTIONS
const loansRequested = createAction('loans/requested')
const loansRequestFailed = createAction('loans/requestFailed')
const loansReceived = createAction('loans/received')
const loansClean = createAction('loans/clean')

const loanApplicationRequested = createAction('loans/application/requested')
const loanApplicationSuccessful = createAction('loans/application/successful')
const loanApplicationFailed = createAction('loans/application/failed')
const loansApplicationUpdate = createAction('loans/application/update')

const shortInterestsPaymentRequested = createAction('loans/shortInterests/paymentRequested')
const shortInterestsPaymentSuccessful = createAction('loans/shortInterests/paymentSuccessful')
const shortInterestsPaymentFailed = createAction('loans/shortInterests/paymentFailed')

const longInterestsPaymentRequested = createAction('loans/longInterests/paymentRequested')
const longInterestsPaymentSuccessful = createAction('loans/longInterests/paymentSuccessful')
const longInterestsPaymentFailed = createAction('loans/longInterests/paymentFailed')

const loanSelectType = createAction('loans/selectType')

// REDUCER
export const initialState = {
  loading: false,
  shortInterestPaymentloading: false,
  loanApplicationloading: false,
  longInterestPaymentLoading: false,
  data: [],
  newLoanApplication: {
    type: null,
    amount: 0
  },
  loanApplicationSuccess: false,
  shortInterestPaymentSuccess: false,
  longInterestPaymentSuccess: false
}

const loansReducer = createReducer(initialState, {
  [loansRequested.type]: (state) => {
    state.loading = true
  },
  [loansReceived.type]: (state, action) => {
    state.loading = false
    state.data = action.payload.data
  },
  [loansRequestFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  // loan application
  [loansApplicationUpdate]: (state, { payload }) => {
    state.newLoanApplication.type = payload.type
    state.newLoanApplication.amount = payload.amount
  },
  [loanApplicationRequested.type]: (state) => {
    state.loanApplicationloading = true
    state.loanApplicationSuccess = false
  },
  [loanApplicationSuccessful.type]: (state, action) => {
    state.loanApplicationloading = false
    state.loanApplicationSuccess = true
    state.newLoanApplication = initialState.newLoanApplication
    state.data = action.payload.data
  },
  [loanApplicationFailed.type]: (state, action) => {
    state.loanApplicationloading = false
    state.loanApplicationSuccess = false
    state.newLoanApplication = initialState.newLoanApplication
    toast.error(action.payload.message)
  },
  // short interest payment
  [shortInterestsPaymentRequested.type]: (state) => {
    state.shortInterestPaymentloading = true
    state.shortInterestPaymentSuccess = false
  },
  [shortInterestsPaymentSuccessful.type]: (state, action) => {
    state.shortInterestPaymentloading = false
    state.shortInterestPaymentSuccess = true
    state.data = action.payload.data
  },
  [shortInterestsPaymentFailed.type]: (state, action) => {
    state.shortInterestPaymentloading = false
    state.shortInterestPaymentSuccess = false
    toast.error(action.payload.message)
  },

  // Long interest payment
  [longInterestsPaymentRequested.type]: (state) => {
    state.longInterestPaymentLoading = true
    state.longInterestPaymentSuccess = false
  },
  [longInterestsPaymentSuccessful.type]: (state, action) => {
    state.longInterestPaymentLoading = false
    state.longInterestPaymentSuccess = true
    state.data = action.payload.data
  },
  [longInterestsPaymentFailed.type]: (state, action) => {
    state.longInterestPaymentLoading = false
    state.longInterestPaymentSuccess = false
    toast.error(action.payload.message)
  },

  [loansClean.type]: () => initialState,

  [loanSelectType.type]: (state, action) => {
    state.newLoanApplication.type = action.payload
  }
})

export default loansReducer

// Public actions
export const loadLoans = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `/games/${id}/loans`,
      onStart: loansRequested.type,
      onSuccess: loansReceived.type,
      onError: loansRequestFailed.type
    })
  )
}

export const applyNewLoans = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  const { newLoanApplication } = currentState.loans

  dispatch(
    apiCallBegan({
      url: `/games/${id}/loans/apply`,
      data: {
        loan_type: newLoanApplication.type || 'short_term',
        loan_amount: newLoanApplication.amount
      },
      method: 'POST',
      onStart: loanApplicationRequested.type,
      onSuccess: loanApplicationSuccessful.type,
      onError: loanApplicationFailed.type
    })
  )
}

export const payShortInterests = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `/games/${id}/loans/pay_interest`,
      data: { loan_type: 'short_term' },
      method: 'POST',
      onStart: shortInterestsPaymentRequested.type,
      onSuccess: shortInterestsPaymentSuccessful.type,
      onError: shortInterestsPaymentFailed.type
    })
  )
}

export const payLongInterests = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `/games/${id}/loans/pay_interest`,
      data: { loan_type: 'long_term' },
      method: 'POST',
      onStart: longInterestsPaymentRequested.type,
      onSuccess: longInterestsPaymentSuccessful.type,
      onError: longInterestsPaymentFailed.type
    })
  )
}

export const cleanLoans = () => (dispatch) => dispatch(loansClean())

export const updateNewLoan = (action) => (dispatch) => {
  dispatch(loansApplicationUpdate(action))
}

export const updateLoanType = (type) => (dispatch) => dispatch(loanSelectType(type))
