import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// ACTIONS

// Sales
const salesDashboardRequested = createAction('dashboard/salesRequested')
const salesDashboardRequestFailed = createAction('dashboard/salesRequestFailed')
const salesDashboardReceived = createAction('dashboard/salesReceived')

const pollingSalesDashboardRequested = createAction('dashboard/pollingSalesRequested')
const pollingSalesDashboardRequestFailed = createAction('dashboard/pollingSalesRequestFailed')
const pollingSalesDashboardReceived = createAction('dashboard/pollingSalesReceived')

// Financials
const financialsDashboardRequested = createAction('dashboard/financialsRequested')
const financialsDashboardRequestFailed = createAction('dashboard/financialsRequestFailed')
const financialsDashboardReceived = createAction('dashboard/financialsReceived')

const pollingFinancialsDashboardRequested = createAction('dashboard/pollingFinancialsRequested')
const pollingFinancialsDashboardRequestFailed = createAction(
  'dashboard/pollingFinancialsRequestFailed'
)
const pollingFinancialsDashboardReceived = createAction('dashboard/pollingFinancialsReceived')

// Products
const productsDashboardRequested = createAction('dashboard/productsRequested')
const productsDashboardRequestFailed = createAction('dashboard/productsRequestFailed')
const productsDashboardReceived = createAction('dashboard/productsReceived')

const pollingProductsDashboardRequested = createAction('dashboard/pollingProductsRequested')
const pollingProductsDashboardRequestFailed = createAction('dashboard/pollingProductsRequestFailed')
const pollingProductsDashboardReceived = createAction('dashboard/pollingProductsReceived')

// Competitors
const competitorsDashboardRequested = createAction('dashboard/competitorsRequested')
const competitorsDashboardRequestFailed = createAction('dashboard/competitorsRequestFailed')
const competitorsDashboardReceived = createAction('dashboard/competitorsReceived')

const pollingCompetitorsDashboardRequested = createAction('dashboard/pollingCompetitorsRequested')
const pollingCompetitorsDashboardRequestFailed = createAction(
  'dashboard/pollingCompetitorsRequestFailed'
)
const pollingCompetitorsDashboardReceived = createAction('dashboard/pollingCompetitorsReceived')

// Markets
const marketsDashboardRequested = createAction('dashboard/marketsRequested')
const marketsDashboardRequestFailed = createAction('dashboard/marketsRequestFailed')
const marketsDashboardReceived = createAction('dashboard/marketsReceived')

const pollingMarketsDashboardRequested = createAction('dashboard/pollingMarketsRequested')
const pollingMarketsDashboardRequestFailed = createAction('dashboard/pollingMarketsRequestFailed')
const pollingMarketsDashboardReceived = createAction('dashboard/pollingMarketsReceived')

const defaultState = {
  data: {},
  loading: false,
  success: false,
  polling: false
}

// REDUCER
export const initialState = {
  competitors: defaultState,
  financials: defaultState,
  markets: defaultState,
  products: defaultState,
  sales: defaultState
}

const dashboardReducer = createReducer(initialState, {
  // Sales
  [salesDashboardRequested.type]: (state) => {
    state.sales.loading = true
    state.sales.success = false
  },
  [salesDashboardReceived.type]: (state, action) => {
    state.sales.loading = false
    state.sales.success = true
    state.sales.data = action.payload.data
  },
  [salesDashboardRequestFailed.type]: (state, action) => {
    state.sales.loading = false
    state.sales.success = false
    toast.error(action.payload.message)
  },
  [pollingSalesDashboardRequested.type]: (state) => {
    state.sales.polling = true
  },
  [pollingSalesDashboardRequestFailed.type]: (state, action) => {
    state.sales.polling = false
    toast.error(action.payload.message)
  },
  [pollingSalesDashboardReceived.type]: (state, action) => {
    state.sales.polling = false
    state.sales.data = action.payload.data
  },
  // Financials
  [financialsDashboardRequested.type]: (state) => {
    state.financials.loading = true
    state.financials.success = false
  },
  [financialsDashboardReceived.type]: (state, action) => {
    state.financials.loading = false
    state.financials.success = true
    state.financials.data = action.payload.data
  },
  [financialsDashboardRequestFailed.type]: (state, action) => {
    state.financials.loading = false
    state.financials.success = false
    toast.error(action.payload.message)
  },
  [pollingFinancialsDashboardRequested.type]: (state) => {
    state.financials.polling = true
  },
  [pollingFinancialsDashboardRequestFailed.type]: (state, action) => {
    state.financials.polling = false
    toast.error(action.payload.message)
  },
  [pollingFinancialsDashboardReceived.type]: (state, action) => {
    state.financials.polling = false
    state.financials.data = action.payload.data
  },
  // Products
  [productsDashboardRequested.type]: (state) => {
    state.products.loading = true
    state.products.success = false
  },
  [productsDashboardReceived.type]: (state, action) => {
    state.products.loading = false
    state.products.success = true
    state.products.data = action.payload.data
  },
  [productsDashboardRequestFailed.type]: (state, action) => {
    state.products.loading = false
    state.products.success = false
    toast.error(action.payload.message)
  },
  [pollingProductsDashboardRequested.type]: (state) => {
    state.products.polling = true
  },
  [pollingProductsDashboardRequestFailed.type]: (state, action) => {
    state.products.polling = false
    toast.error(action.payload.message)
  },
  [pollingProductsDashboardReceived.type]: (state, action) => {
    state.products.polling = false
    state.products.data = action.payload.data
  },
  // Competitors
  [competitorsDashboardRequested.type]: (state) => {
    state.competitors.loading = true
    state.competitors.success = false
  },
  [competitorsDashboardReceived.type]: (state, action) => {
    state.competitors.loading = false
    state.competitors.success = true
    state.competitors.data = action.payload.data
  },
  [competitorsDashboardRequestFailed.type]: (state, action) => {
    state.competitors.loading = false
    state.competitors.success = false
    toast.error(action.payload.message)
  },
  [pollingCompetitorsDashboardRequested.type]: (state) => {
    state.competitors.polling = true
  },
  [pollingCompetitorsDashboardRequestFailed.type]: (state, action) => {
    state.competitors.polling = false
    toast.error(action.payload.message)
  },
  [pollingCompetitorsDashboardReceived.type]: (state, action) => {
    state.competitors.polling = false
    state.competitors.data = action.payload.data
  },
  // Markets
  [marketsDashboardRequested.type]: (state) => {
    state.markets.loading = true
    state.markets.success = false
  },
  [marketsDashboardReceived.type]: (state, action) => {
    state.markets.loading = false
    state.markets.success = true
    state.markets.data = action.payload.data
  },
  [marketsDashboardRequestFailed.type]: (state, action) => {
    state.markets.loading = false
    state.markets.success = false
    toast.error(action.payload.message)
  },
  [pollingMarketsDashboardRequested.type]: (state) => {
    state.markets.polling = true
  },
  [pollingMarketsDashboardRequestFailed.type]: (state, action) => {
    state.markets.polling = false
    toast.error(action.payload.message)
  },
  [pollingMarketsDashboardReceived.type]: (state, action) => {
    state.markets.polling = false
    state.markets.data = action.payload.data
  }
})

export default dashboardReducer

// PUBLIC ACTIONS
// Sales
export const loadSalesDashboard = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/dashboard/sales`,
      onStart: salesDashboardRequested.type,
      onSuccess: salesDashboardReceived.type,
      onError: salesDashboardRequestFailed.type
    })
  )
}

export const pollingSalesDashboard = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/dashboard/sales`,
      onStart: pollingSalesDashboardRequested.type,
      onSuccess: pollingSalesDashboardReceived.type,
      onError: pollingSalesDashboardRequestFailed.type
    })
  )
}

// Financials
export const loadFinancialsDashboard = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/dashboard/financials`,
      onStart: financialsDashboardRequested.type,
      onSuccess: financialsDashboardReceived.type,
      onError: financialsDashboardRequestFailed.type
    })
  )
}

export const pollingFinancialsDashboard = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/dashboard/financials`,
      onStart: pollingFinancialsDashboardRequested.type,
      onSuccess: pollingFinancialsDashboardReceived.type,
      onError: pollingFinancialsDashboardRequestFailed.type
    })
  )
}

// Products
export const loadProductsDashboard = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/dashboard/products`,
      onStart: productsDashboardRequested.type,
      onSuccess: productsDashboardReceived.type,
      onError: productsDashboardRequestFailed.type
    })
  )
}

export const pollingProductsDashboard = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/dashboard/products`,
      onStart: pollingProductsDashboardRequested.type,
      onSuccess: pollingProductsDashboardReceived.type,
      onError: pollingProductsDashboardRequestFailed.type
    })
  )
}

// Competitors
export const loadCompetitorsDashboard = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/dashboard/competitors`,
      onStart: competitorsDashboardRequested.type,
      onSuccess: competitorsDashboardReceived.type,
      onError: competitorsDashboardRequestFailed.type
    })
  )
}

export const pollingCompetitorsDashboard = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/dashboard/competitors`,
      onStart: pollingCompetitorsDashboardRequested.type,
      onSuccess: pollingCompetitorsDashboardReceived.type,
      onError: pollingCompetitorsDashboardRequestFailed.type
    })
  )
}

// Markets
export const loadMarketsDashboard = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/dashboard/markets`,
      onStart: marketsDashboardRequested.type,
      onSuccess: marketsDashboardReceived.type,
      onError: marketsDashboardRequestFailed.type
    })
  )
}

export const pollingMarketsDashboard = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `/games/${id}/dashboard/markets`,
      onStart: pollingMarketsDashboardRequested.type,
      onSuccess: pollingMarketsDashboardReceived.type,
      onError: pollingMarketsDashboardRequestFailed.type
    })
  )
}
