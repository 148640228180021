import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import ROUTES from 'constants/routes'
import { useSelector } from 'react-redux'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const loggedIn = useSelector((state) => state.auth.loggedIn)

  return loggedIn ? <Route {...rest} /> : <Redirect to={{ pathname: ROUTES.APP_URL_LOGIN }} />
}

export default PrivateRoute
