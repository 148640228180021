import React from 'react'

export default function LoadingDots() {
  return (
    <div className="loading-dots">
      <div className="loading-dots__dot dot1" />
      <div className="loading-dots__dot dot2" />
      <div className="loading-dots__dot dot3" />
    </div>
  )
}
