import React from 'react'
import ErrorBoundaryProvider from 'utils/bugsnag'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from 'components/App'
import reportWebVitals from './reportWebVitals'

// i18n settings
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

// Redux
import store from './store'

import 'stylesheets/application.scss'

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundaryProvider>
      <I18nextProvider i18n={i18n}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </I18nextProvider>
    </ErrorBoundaryProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
