import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import rootReducer from './reducers'

// import logger from "./middleware/logger";
import api from 'store/middleware/api'

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware(), api]
})

export default store
