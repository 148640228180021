import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from './selectors'

// ACTIONS
const bankRequested = createAction('bank/requested')
const bankRequestFailed = createAction('bank/requestFailed')
const bankReceived = createAction('bank/received')
const bankClean = createAction('bank/clean')

const bankUpdateRequested = createAction('bank/updateRequested')
const bankUpdatedSuccess = createAction('bank/updateSuccess')
const bankUpdateRequestFailed = createAction('bank/updateRequestFailed')

const addCoinsToBank = createAction('bank/addCoinsToBank')
const removeCoinsToBank = createAction('bank/removeCoinsToBank')

const factorRequested = createAction('bank/factorRequested')
const factorRequestedSuccess = createAction('bank/factorSuccess')
const factorRequestedFailed = createAction('bank/factorFailed')

// REDUCER
export const initialState = {
  data: null,
  loading: false,
  updateSuccess: false,
  updateLoading: false,
  factorSuccess: false,
  factorLoading: false
}

const bankReducer = createReducer(initialState, {
  [bankRequested.type]: (state) => {
    state.loading = true
  },
  [bankReceived.type]: (state, action) => {
    state.loading = false
    state.factorSuccess = false
    state.data = action.payload
  },
  [bankRequestFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  [bankClean.type]: () => initialState,
  [addCoinsToBank.type]: (state, action) => {
    state.data.cash += action.payload
  },
  [removeCoinsToBank.type]: (state, action) => {
    state.data.cash -= action.payload
  },

  // update
  [bankUpdateRequested.type]: (state) => {
    state.updateLoading = true
    state.updateSuccess = false
  },
  [bankUpdatedSuccess.type]: (state, action) => {
    state.data = action.payload.data
    state.updateSuccess = true
    state.updateLoading = false
  },
  [bankUpdateRequestFailed.type]: (state, action) => {
    state.updateLoading = false
    toast.error(action.payload.message)
  },

  // factor
  [factorRequested.type]: (state) => {
    state.factorLoading = true
    state.factorSuccess = false
  },
  [factorRequestedSuccess.type]: (state) => {
    state.factorSuccess = true
    state.factorLoading = false
  },
  [factorRequestedFailed.type]: (state, action) => {
    state.factorLoading = false
    toast.error(action.payload.message)
  }
})

export default bankReducer

// Public actions
export const loadUserBank = () =>
  apiCallBegan({
    url: `/user/bank`,
    onStart: bankRequested.type,
    onSuccess: bankReceived.type,
    onError: bankRequestFailed.type
  })

export const updateTradeReceivables = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())
  dispatch(
    apiCallBegan({
      url: `/games/${id}/orders/update_trade_receivables`,
      method: 'patch',
      onStart: bankUpdateRequested.type,
      onSuccess: bankUpdatedSuccess.type,
      onError: bankUpdateRequestFailed.type
    })
  )
}

export const factorReceivables = (data) => (dispatch, getState) => {
  const id = gameIdSelector(getState())
  dispatch(
    apiCallBegan({
      url: `/games/${id}/orders/factor`,
      method: 'post',
      data,
      onStart: factorRequested.type,
      onSuccess: factorRequestedSuccess.type,
      onError: factorRequestedFailed.type
    })
  )
}

export const cleanUserBank = () => (dispatch) => dispatch(bankClean())

export const addCoins = (coins) => (dispatch) => dispatch(addCoinsToBank(coins))

export const removeCoins = (coins) => (dispatch) => dispatch(removeCoinsToBank(coins))
