import axios from 'axios'
import API from '../../constants/api'
import authHeader from './auth-header'
import { tokenRefreshed } from '../apiCalls'

const tokenRefresh = async (dispatch, retryAction) => {
  const refreshToken = localStorage.getItem('smb_refresh_token')

  try {
    const response = await axios.post(
      API.REFRESH_TOKEN_URL,
      {
        refresh_token: refreshToken,
        grant_type: 'refresh_token'
      },
      authHeader()
    )

    // Set the new access token.
    dispatch(tokenRefreshed(response.data))
    localStorage.setItem('smb_access_token', response.data.access_token)
    localStorage.setItem('smb_refresh_token', response.data.refresh_token)
    retryAction()
  } catch (error) {
    // Show Dialog session expired.
  }
}

export default tokenRefresh
