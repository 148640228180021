import { createAction, createReducer } from '@reduxjs/toolkit'
import i18n from 'i18n'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

const cashAlert = createAction('cashAlert/start')
const showAlertWarning = createAction('cashAlert/toggleAlert')

const fixPlayerRequested = createAction('fixPlayer/requested')
const fixPlayerReceived = createAction('fixPlayer/received')
const fixPlayerFailed = createAction('fixPlayer/failed')

//  REDUCER
export const initialState = {
  loading: false,
  showAlertWarning: false,
  errorKey: null,
  fixPlayerSuccess: false
}

const cashAlertReducer = createReducer(initialState, {
  [showAlertWarning.type]: (state, action) => {
    state.showAlertWarning = action.payload
  },
  [cashAlert.type]: (state, action) => {
    state.errorKey = action.payload
  },
  [fixPlayerRequested.type]: (state, action) => {
    state.loading = true
    state.fixPlayerSuccess = false
    state.data = action.payload
  },
  [fixPlayerReceived.type]: (state, action) => {
    state.loading = false
    state.fixPlayerSuccess = true
    state.data = action.payload
    toast.success(i18n.t(`cash_alert.fix.${state.errorKey}`))
    state.errorKey = null
  },
  [fixPlayerFailed.type]: (state, action) => {
    state.loading = false
    state.fixPlayerSuccess = false
    state.data = action.payload
    toast.error(action.payload.message)
    state.errorKey = null
  }
})

export default cashAlertReducer

export const setShowAlertWarning = (value) => (dispatch) => dispatch(showAlertWarning(value))

export const loadCashAlert = (errorKey) => (dispatch) => {
  dispatch(setShowAlertWarning(true))
  dispatch(cashAlert(errorKey))
}

export const fixPlayer = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `/games/${id}/error_management/fix_player`,
      method: 'post',
      onStart: fixPlayerRequested.type,
      onSuccess: fixPlayerReceived.type,
      onError: fixPlayerFailed.type
    })
  )
}
