import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ROUTES from 'constants/routes'
import ADMIN from 'constants/admin'
import Logo from 'assets/icons/logo.png'

// Redux
import { signInPlayer } from 'store/auth'
import { USER_TYPES } from 'constants/users'

export default function Login() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [code, setCode] = useState('')
  const { loggedIn, userType } = useSelector((store) => store.auth)

  const history = useHistory()

  const handleSubmit = (e) => {
    e.preventDefault()

    if (code) dispatch(signInPlayer({ access_code: code }))
    else toast.error(t('login.errors.no_code'), { autoClose: 3000 })
  }

  useEffect(() => {
    if (loggedIn && userType === USER_TYPES.player) history.replace(ROUTES.APP_URL_HOME)
    if (loggedIn && userType === USER_TYPES.facilitator)
      history.replace(ROUTES.APP_URL_FACILITATORS)
  }, [loggedIn, userType])

  return (
    <div className="login fade-in">
      <img src={Logo} alt="The Rivals" className="login__logo" />
      <form className="login__form" onSubmit={handleSubmit}>
        <input
          placeholder="Login code"
          className="login__input"
          name="accessCode"
          value={code}
          onChange={(event) => {
            setCode(event.target.value)
          }}
        />
        <input type="submit" className="primary-button w-100" value="Sign In" />
        <a href={`${ADMIN.ADMIN_URL}plans?game_type=the-rivals`} className="secondary-button w-100 mt-5">{t('login.dont_have_login_code')}</a>
      </form>
    </div>
  )
}
