import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { currentGeneralStateSelector, gameIdSelector } from 'store/user/selectors'

// ACTIONS
const ordersRequested = createAction('orders/requested')
const ordersRequestFailed = createAction('orders/requestFailed')
const ordersReceived = createAction('orders/received')
// Orders delivery
const deliveryRequested = createAction('orders/deliveryRequested')
const deliveryRequestFailed = createAction('orders/deliveryRequestFailed')
const deliveryReceived = createAction('orders/deliveryReceived')
// Orders penalty
const payPenaltyRequested = createAction('orders/penaltyRequested')
const payPenaltyRequestFailed = createAction('orders/penaltyRequestFailed')
const penaltyPaid = createAction('orders/penaltyPaid')
const toggleSelectedOrder = createAction('orders/toggleSelected')
// Backlog orders
const backlogOrdersRequested = createAction('backlogOrders/requested')
const backlogOrdersRequestFailed = createAction('backlogOrders/requestFailed')
const backlogOrdersReceived = createAction('backlogOrders/received')

const ordersClean = createAction('orders/clean')

const showAlertMyOrders = createAction('orders/showAlert')

// REDUCER
export const initialState = {
  loading: false,
  loadingBacklogOrders: false,
  deliveryOrdersLoading: false,
  payPenaltyLoading: false,
  data: [],
  backlogOrders: [],
  selectedOrders: [],
  requestOrdersSuccess: false,
  requestBacklogOrdersSuccess: false,
  deliveryOrdersSuccess: false,
  payPenaltySuccess: false,
  showAlertMyOrders: false
}

const ordersReducer = createReducer(initialState, {
  [ordersRequested.type]: (state) => {
    state.loading = true
    state.requestOrdersSuccess = false
  },
  [ordersReceived.type]: (state, action) => {
    state.loading = false
    state.selectedOrders = []
    state.data = action.payload.data
    state.requestOrdersSuccess = true
  },
  [ordersRequestFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  // orders delivery
  [deliveryRequested.type]: (state) => {
    state.deliveryOrdersLoading = true
    state.deliveryOrdersSuccess = false
  },
  [deliveryReceived.type]: (state, action) => {
    state.deliveryOrdersLoading = false
    state.selectedOrders = []
    state.data = action.payload.data
    state.deliveryOrdersSuccess = true
    state.showAlertMyOrders = false
  },
  [deliveryRequestFailed.type]: (state, action) => {
    state.loading = false
    state.showAlertMyOrders = false
    state.deliveryOrdersLoading = false
    toast.error(action.payload.message)
  },
  [showAlertMyOrders.type]: (state, action) => {
    state.showAlertMyOrders = action.payload
  },
  [toggleSelectedOrder.type]: (state, action) => {
    if (!state.selectedOrders.find((order) => order === action.payload)) {
      state.selectedOrders = [...state.selectedOrders, action.payload]
    } else {
      state.selectedOrders = state.selectedOrders.filter((order) => order !== action.payload)
    }
  },
  // Orders delivery penalty
  [payPenaltyRequested.type]: (state) => {
    state.payPenaltyLoading = true
    state.payPenaltySuccess = false
  },
  [penaltyPaid.type]: (state, action) => {
    state.payPenaltyLoading = false
    state.payPenaltySuccess = true
    state.data = action.payload.data
  },
  [payPenaltyRequestFailed.type]: (state, action) => {
    state.payPenaltyLoading = false
    state.payPenaltySuccess = false
    toast.error(action.payload.message)
  },
  [ordersClean.type]: () => initialState,
  // Backlog orders
  [backlogOrdersRequested.type]: (state) => {
    state.loadingBacklogOrders = true
    state.requestBacklogOrdersSuccess = false
  },
  [backlogOrdersReceived.type]: (state, action) => {
    state.loadingBacklogOrders = false
    state.backlogOrders = action.payload.data
    state.requestBacklogOrdersSuccess = true
  },
  [backlogOrdersRequestFailed.type]: (state, action) => {
    state.loadingBacklogOrders = false
    toast.error(action.payload.message)
  }
})

export default ordersReducer

// Public actions
export const loadOrders = (params) => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `/games/${id}/orders`,
      params,
      onStart: ordersRequested.type,
      onSuccess: ordersReceived.type,
      onError: ordersRequestFailed.type
    })
  )
}

export const loadBacklogOrders = (params) => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `/games/${id}/orders`,
      params,
      onStart: backlogOrdersRequested.type,
      onSuccess: backlogOrdersReceived.type,
      onError: backlogOrdersRequestFailed.type
    })
  )
}

export const deliverOrders = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `/games/${id}/orders/delivery`,
      data: { orders_ids: currentState.orders.selectedOrders },
      method: 'POST',
      onStart: deliveryRequested.type,
      onSuccess: deliveryReceived.type,
      onError: deliveryRequestFailed.type
    })
  )
}

export const setShowAlertMyOrders = (value) => (dispatch) => dispatch(showAlertMyOrders(value))

export const ordersToDeliver = () => (dispatch, getState) => {
  const currentState = getState()
  const currentQuarter = currentGeneralStateSelector(currentState)
  const currentQuality = currentState.productQualityStrategy.current_index
  const { data, selectedOrders } = currentState.orders

  // No orders to deliver, not q4 or nothing selected.
  if (selectedOrders.length > 0 || currentQuarter !== 'q4' || data.length === 0)
    return dispatch(deliverOrders())

  const finishedGoods = currentState.production.data.finished_goods
  const canDeliver = data.some((order) => {
    return (
      order.quantity_of_products <= finishedGoods[order.requested_product] &&
      currentQuality >= order.required_product_quality_index
    )
  })

  return canDeliver ? dispatch(setShowAlertMyOrders(true)) : dispatch(deliverOrders())
}

export const payDeliveryPenalty = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `/games/${id}/orders/delivery_penalty`,
      method: 'POST',
      onStart: payPenaltyRequested.type,
      onSuccess: penaltyPaid.type,
      onError: payPenaltyRequestFailed.type
    })
  )
}

export const toggleOrder = (order) => (dispatch) => dispatch(toggleSelectedOrder(order))

export const cleanOrders = () => (dispatch) => dispatch(ordersClean())
