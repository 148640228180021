import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// ACTIONS
const gameRequested = createAction('game/requested')
const gameRequestFailed = createAction('game/requestFailed')
const gameReceived = createAction('game/received')
const gameClean = createAction('game/clean')

const gameChangeTurnRequested = createAction('game/changeTurnRequested')
const gameChangeTurnRequestFailed = createAction('game/changeTurnRequestFailed')
const gameChangeTurnReceived = createAction('game/changeTurnReceived')

// REDUCER
export const initialState = {
  data: null,
  loading: false,
  changeTurnLoading: false,
  changeTurnSuccess: false
}

const gameReducer = createReducer(initialState, {
  [gameRequested.type]: (state) => {
    state.loading = true
  },
  [gameReceived.type]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [gameRequestFailed.type]: (state) => {
    state.loading = false
  },
  [gameClean.type]: () => initialState,
  [gameChangeTurnRequested.type]: (state) => {
    state.changeTurnLoading = true
    state.changeTurnSuccess = false
  },
  [gameChangeTurnRequestFailed.type]: (state, action) => {
    state.changeTurnLoading = false
    toast.error(action.payload.message)
  },
  [gameChangeTurnReceived.type]: (state) => {
    state.changeTurnLoading = false
    state.changeTurnSuccess = true
  }
})

export default gameReducer

// Public actions
export const loadUserGame = () =>
  apiCallBegan({
    url: `/user/game`,
    onStart: gameRequested.type,
    onSuccess: gameReceived.type,
    onError: gameRequestFailed.type
  })

export const cleanUserGame = () => (dispatch) => dispatch(gameClean())

export const changeTurn = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `games/${id}/turns/change`,
      method: 'post',
      onStart: gameChangeTurnRequested.type,
      onSuccess: gameChangeTurnReceived.type,
      onError: gameChangeTurnRequestFailed.type
    })
  )
}
