const ROUTES = {
  // Landing page
  LANDING_HOME: '/',
  LANDING_GAME_OVERVIEW: '/game-overview',
  LANDING_ABOUT: '/about',
  LANDING_PRIVACY_POLICY: '/privacy-policy',
  // GAME URLS
  APP_URL_HOME: '/game',
  APP_URL_LOGIN: '/login',
  APP_URL_FACILITATORS: '/facilitators',
  BALANCE_SHEET: '/balance-sheet',
  BEGINNING_OF_THE_YEAR: '/beginning-of-the-year',
  CALCULATIONS: '/calculations',
  CASH_FLOW: '/cash-flow',
  COST_ACCOUNTING: '/cost-accounting',
  CONTRIBUTION_MARGIN: '/contribution-margin',
  DECISION_AIDS: '/decision-aids',
  MARKET_ROUND: '/market-round',
  END_OF_THE_YEAR: '/end-of-the-year',
  FINANCES: '/finances',
  INCOME_STATEMENT: '/income-statement',
  KPI_ANALYSIS: '/kpi-analysis',
  LIQUIDITY_CALCULATION: '/liquidity-calculation',
  MARKET_ANALYSIS: '/market-analysis',
  REPORTS: '/reports',
  MY_ORDERS: '/my-orders',
  PRODUCTION_PLAN: '/production-plan',
  QUARTERLY_ACTIONS: '/quarterly-actions',
  SETTINGS: '/settings',
  GAME_INFORMATION: '/game-information',
  // Market Forecast
  MARKETS_FORECAST: '/markets-forecast',
  FORECASTS: '/forecasts',
  // Investments
  BUY_MACHINE: '/buy-machine',
  SELL_MACHINE: '/sell-machine',
  BUY_PLANT: '/buy-plant',
  RENT_PLANT: '/rent-plant',
  SELL_PLANT: '/sell-plant',
  PRODUCT_CHANGEOVER: '/product-changeover',
  // Graphics
  GRAPHICS: '/graphics',
  WAITING_JOIN_GRAPHICS: '/waiting-join-graphics',
  MARKET_POSITION: '/market-position',
  COMPETITION_ANALYSIS: '/competition-analysis',
  MARKETING: '/marketing',
  PRODUCT_QUALITY_INDEX: '/product-quality-index',
  TRAINING_INDEX: '/training-index',
  QUANTITY_SALES: '/quantity-sales',
  MARKET_SHARE: '/market-share',
  LABOR_PRODUCTIVITY: '/labor-productivity',
  ANNUAL_RESULT: '/annual-result',
  CHANGE_IN_PROFIT: '/change-in-profit',
  RETURN_ON_EQUITY: '/return-on-equity',
  EQUITY_CAPITAL: '/equity-capital',
  BORROWED_CAPITAL_RATIO: '/borrowed-capital-ratio',
  DIVIDENDS: '/dividends',
  SHARE_PRICE: '/share-price',
  AVG_RESULT: '/avg-result',
  CUMULATIVE_SALES: '/cumulative-sales',
  CUMULATIVE_SALES_H_BASIC: '/cumulative-sales-h-basic',
  CUMULATIVE_SALES_H_PLUS: '/cumulative-sales-h-plus',
  CUMULATIVE_SALES_H_ULTRA: '/cumulative-sales-h-ultra',
  CUMULATIVE_SALES_NEXT_GEN: '/cumulative-sales-next-gen',
  CUMULATIVE_SALES_MARKET1: '/cumulative-sales-market1',
  CUMULATIVE_SALES_MARKET2: '/cumulative-sales-market2',
  CUMULATIVE_SALES_MARKET3: '/cumulative-sales-market3',
  CUMULATIVE_SALES_MARKET4: '/cumulative-sales-market4',
  CUMULATIVE_SALES_MARKET5: '/cumulative-sales-market5',
  CURRENT_GRAPHICS: '/current-graphics',
  // Dashboard
  DASHBOARD: '/dashboard',
  SALES: '/sales',
  PRODUCTS: '/products',
  MARKETS: '/markets',
  FINANCIALS: '/financials',
  COMPETITORS: '/competitors',
  // Participants form
  PARTICIPANTS: '/participants',
  INTRO: '/intro',
  BUSINESS_PLAN_AND_STRATEGY: '/business-plan-and-strategy',
  INVESTMENT_CALCULATION: '/investment-calculation',
  STRATEGIC_EVALUATION: '/strategic-evaluation',
  CONCLUSION: '/conclusion'
}

export const PARTICIPANTS_FORM_ROUTES = [
  `${ROUTES.PARTICIPANTS}${ROUTES.INTRO}`,
  `${ROUTES.PARTICIPANTS}${ROUTES.BUSINESS_PLAN_AND_STRATEGY}`,
  `${ROUTES.PARTICIPANTS}${ROUTES.INVESTMENT_CALCULATION}`,
  `${ROUTES.PARTICIPANTS}${ROUTES.STRATEGIC_EVALUATION}`,
  `${ROUTES.PARTICIPANTS}${ROUTES.CONCLUSION}`
]

export const DASHBOARD_ROUTES = [
  `${ROUTES.DASHBOARD}${ROUTES.SALES}`,
  `${ROUTES.DASHBOARD}${ROUTES.PRODUCTS}`,
  `${ROUTES.DASHBOARD}${ROUTES.MARKETS}`,
  `${ROUTES.DASHBOARD}${ROUTES.FINANCIALS}`,
  `${ROUTES.DASHBOARD}${ROUTES.COMPETITORS}`
]

export default ROUTES
