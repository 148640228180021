import { createAction, createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import i18n from 'i18n'
import { apiCallBegan } from 'store/apiCalls'
import { gameIdSelector } from 'store/user/selectors'

// current
const currentBalanceSheetRequested = createAction('balanceSheet/currentRequested')
const currentBalanceSheetReceived = createAction('balanceSheet/currentReceived')
const currentBalanceSheetFailed = createAction('balanceSheet/currentFailed')

// index
const balanceSheetRequested = createAction('balanceSheet/requested')
const balanceSheetReceived = createAction('balanceSheet/received')
const balanceSheetFailed = createAction('balanceSheet/failed')

// Calculate
const balanceSheetCalculateRequested = createAction('balanceSheet/calculateRequested')
const balanceSheetCalculateReceived = createAction('balanceSheet/calculateReceived')
const balanceSheetCalculateFailed = createAction('balanceSheet/calculateFailed')

// Dividends
const addSelectedDividends = createAction('balanceSheet/addSelectedDividends')
const removeSelectedDividends = createAction('balanceSheet/removeSelectedDividends')

const dividendsExtractRequested = createAction('balanceSheet/dividendsRequested')
const dividendsExtractReceived = createAction('balanceSheet/dividendsReceived')
const dividendsExtractFailed = createAction('balanceSheet/dividendsFailed')

const waitDividendsExtractRequested = createAction('balanceSheet/waitDividendsRequested')
const waitDividendsExtractReceived = createAction('balanceSheet/waitDividendsReceived')
const waitDividendsExtractFailed = createAction('balanceSheet/waitDividendsFailed')

// Share capital
const updateShareCapitalRequested = createAction('balanceSheet/updateShareCapitalRequested')
const updateShareCapitalReceived = createAction('balanceSheet/updateShareCapitalReceived')
const updateShareCapitalFailed = createAction('balanceSheet/updateShareCapitalFailed')

const addCurrentShareCapital = createAction('balanceSheet/addCurrentShareCapital')
const removeCurrentShareCapital = createAction('balanceSheet/removeCurrentShareCapital')

// REDUCER
export const initialState = {
  loading: false,
  items: [],
  current: null,
  currentSuccess: false,
  success: false,
  extractDividendsSuccess: false,
  extractDividendsLoading: false,
  selectedDividends: 0,
  updateShareCapitalSuccess: false,
  waitDividendsExtractLoading: false,
  waitDividendsExtractSuccess: false
}

// employees salaries

const balanceSheetReducer = createReducer(initialState, {
  // current
  [currentBalanceSheetRequested.type]: (state) => {
    state.loading = true
    state.currentSuccess = false
  },
  [currentBalanceSheetReceived.type]: (state, action) => {
    state.loading = false
    state.currentSuccess = true
    state.current = action.payload.data
  },
  [currentBalanceSheetFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  // index
  [balanceSheetRequested.type]: (state) => {
    state.loading = true
    state.success = false
  },
  [balanceSheetReceived.type]: (state, action) => {
    state.loading = false
    state.success = true
    state.items = action.payload.data
  },
  [balanceSheetFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  // Calculate
  [balanceSheetCalculateRequested.type]: (state) => {
    state.loading = true
    state.calculateSuccess = false
  },
  [balanceSheetCalculateReceived.type]: (state) => {
    state.loading = false
    state.calculateSuccess = true
  },
  [balanceSheetCalculateFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  // Dividends
  [addSelectedDividends.type]: (state) => {
    const dividends = state.selectedDividends + 10

    state.selectedDividends =
      dividends > state.current.available_dividends ? state.current.available_dividends : dividends
  },
  [removeSelectedDividends.type]: (state) => {
    const dividends = state.selectedDividends - 10

    state.selectedDividends = dividends < 0 ? 0 : dividends
  },
  [dividendsExtractRequested.type]: (state) => {
    state.extractDividendsLoading = true
    state.extractDividendsSuccess = false
  },
  [dividendsExtractReceived.type]: (state) => {
    state.extractDividendsLoading = false
    state.extractDividendsSuccess = true
  },
  [dividendsExtractFailed.type]: (state, action) => {
    state.extractDividendsLoading = false
    state.extractDividendsSuccess = false
    toast.error(action.payload.message)
  },
  [waitDividendsExtractRequested.type]: (state) => {
    state.waitDividendsExtractLoading = true
    state.waitDividendsExtractSuccess = false
  },
  [waitDividendsExtractReceived.type]: (state) => {
    state.waitDividendsExtractLoading = false
    state.waitDividendsExtractSuccess = true
  },
  [waitDividendsExtractFailed.type]: (state, action) => {
    state.waitDividendsExtractLoading = false
    state.waitDividendsExtractSuccess = false
    toast.error(action.payload.message)
  },
  // Share capital
  [updateShareCapitalRequested.type]: (state) => {
    state.loading = true
    state.updateShareCapitalSuccess = false
  },
  [updateShareCapitalReceived.type]: (state) => {
    state.loading = false
    state.updateShareCapitalSuccess = true
    toast.success(i18n.t('finances.equity_capital.share_capital_success'))
  },
  [updateShareCapitalFailed.type]: (state, action) => {
    state.loading = false
    toast.error(action.payload.message)
  },
  [addCurrentShareCapital.type]: (state) => {
    state.current.share_capital += 10
  },
  [removeCurrentShareCapital.type]: (state) => {
    const shareCapital = state.current.share_capital - 10

    state.current.share_capital = shareCapital < 0 ? 0 : shareCapital
  }
})

export default balanceSheetReducer

// employees current salaries
export const loadCurrentbalanceSheet = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `games/${id}/balance_sheets/current`,
      onStart: currentBalanceSheetRequested.type,
      onSuccess: currentBalanceSheetReceived.type,
      onError: currentBalanceSheetFailed.type
    })
  )
}

// employees salaries
export const loadBalanceSheet = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  dispatch(
    apiCallBegan({
      url: `games/${id}/balance_sheets/`,
      onStart: balanceSheetRequested.type,
      onSuccess: balanceSheetReceived.type,
      onError: balanceSheetFailed.type
    })
  )
}

export const calculateBalanceSheet = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `games/${id}/balance_sheets/calculate`,
      method: 'post',
      onStart: balanceSheetCalculateRequested.type,
      onSuccess: balanceSheetCalculateReceived.type,
      onError: balanceSheetCalculateFailed.type
    })
  )
}

// Dividends
export const addDividends = () => (dispatch) => dispatch(addSelectedDividends())
export const removeDividends = () => (dispatch) => dispatch(removeSelectedDividends())

export const extractDividends = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  const { selectedDividends: amount } = currentState.balanceSheet

  dispatch(
    apiCallBegan({
      url: `games/${id}/dividends/extract`,
      method: 'post',
      data: { amount },
      onStart: dividendsExtractRequested.type,
      onSuccess: dividendsExtractReceived.type,
      onError: dividendsExtractFailed.type
    })
  )
}

export const wait = () => (dispatch, getState) => {
  const id = gameIdSelector(getState())

  dispatch(
    apiCallBegan({
      url: `games/${id}/dividends/wait`,
      method: 'post',
      onStart: waitDividendsExtractRequested.type,
      onSuccess: waitDividendsExtractReceived.type,
      onError: waitDividendsExtractFailed.type
    })
  )
}

export const updateShareCapital = () => (dispatch, getState) => {
  const currentState = getState()
  const id = gameIdSelector(currentState)
  const shareCapital = currentState.balanceSheet.current.share_capital

  dispatch(
    apiCallBegan({
      url: `games/${id}/share_capital/update`,
      method: 'post',
      data: { share_capital: shareCapital },
      onStart: updateShareCapitalRequested.type,
      onSuccess: updateShareCapitalReceived.type,
      onError: updateShareCapitalFailed.type
    })
  )
}

export const addShareCapital = () => (dispatch) => dispatch(addCurrentShareCapital())
export const removeShareCapital = () => (dispatch) => dispatch(removeCurrentShareCapital())
