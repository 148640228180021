import React, { useState, useEffect, memo } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router-dom'

import { updateCurrentTeam } from 'store/user/info'
import ROUTES from 'constants/routes'
import LoadingDots from 'components/Shared/LoadingDots'
import { isFacilitatorSelector } from 'store/user/selectors'
import GameCard from './GameCard'

function Facilitators() {
  const { t } = useTranslation()
  const { changeTeamSuccess, data: user } = useSelector((state) => state.user.info)
  const [teamId, setTeamId] = useState(null)
  const [gameId, setGameId] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const handleSubmit = () => dispatch(updateCurrentTeam(teamId))
  const selectTeam = (id) => setTeamId(id)
  const selectGame = (id) => setGameId(id)
  const isFacilitator = useSelector(isFacilitatorSelector)

  useEffect(() => {
    if (changeTeamSuccess) history.replace(ROUTES.APP_URL_HOME)
  }, [changeTeamSuccess])

  if (!user) return <LoadingDots />
  if (!isFacilitator) return <Redirect to={ROUTES.APP_URL_HOME} />

  return (
    <div className={`facilitators ${gameId ? 'facilitator--centered' : ''}`}>
      <h2 className="facilitators__title">{t('facilitators.select_game')}</h2>
      <div className="facilitators__form">
        <div className="facilitators__games">
          {user.games.map((game) => (
            <GameCard
              game={game}
              selectGameHandler={selectGame}
              selectTeamHandler={selectTeam}
              selectedGameId={gameId}
            />
          ))}
        </div>
        {gameId && (
          <button
            type="button"
            className="primary-button facilitators__submit fade-in"
            onClick={handleSubmit}
          >
            {t('facilitators.go_to_game')}
          </button>
        )}
      </div>
    </div>
  )
}

export default memo(Facilitators)
